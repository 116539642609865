import React from "react";
import { withTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import SimpleList from "./SimpleList";
import RouteLink from "./RouteLink";
import SimpleLink from "./SimpleLink";
import { Links } from "../configs/LinkConfig";
import { Routes } from "../configs/RouteConfig";
import MediaQuery from "react-responsive";
import MediaVariables from "../styles/base/sharedVariables.scss";
import MobilePullDown from "./MobilePullDown";
import { checkIfKioskRoute } from "../utils/Helper";

const LinksFooter = (props) => {
  return (
    <>
      <MediaQuery minDeviceWidth={MediaVariables.bigPage}>
        <Container>
          <Row className="show-grid">
            {!checkIfKioskRoute(props.site) && (
              <>
                <Col xs={12} sm={3} className="">
                  <SimpleList
                    label={props.t("OFFERS PORTAL")}
                    values={[
                      <RouteLink
                        label={props.t("SERVICES")}
                        link={Routes.services}
                      />,
                      <RouteLink
                        label={props.t("PRODUCTS")}
                        link={Routes.products}
                      />,
                      <RouteLink
                        label={props.t("TOS - PYREXX SERVICES")}
                        link={Routes.tosforpyrexxservices}
                      />,
                      <RouteLink
                        label={props.t("TOS - PYREXX PRODUCT SALES")}
                        link={Routes.tosforpyrexxproductsales}
                      />,
                    ]}
                  />
                </Col>
                <Col xs={12} sm={3} className="">
                  <SimpleList
                    label={props.t("SUPPORT")}
                    values={[
                      <RouteLink
                        label={props.t("CONTACT")}
                        link={Routes.contactinfo}
                      />,
                    ]}
                  />
                </Col>
                <Col xs={12} sm={3} className="" />
                <Col xs={12} sm={3} className="">
                  <SimpleList
                    label={props.t("PYREXX GMBH")}
                    values={[
                      <SimpleLink
                        label={props.t("ABOUT US")}
                        url={Links.aboutUs}
                        target="_BLANK"
                      />,
                      <SimpleLink
                        label={Links.pyrexx.replace(/(^\w+:|^)\/\//, "")}
                        url={Links.pyrexx}
                        target="_BLANK"
                      />,
                      <RouteLink
                        label={props.t("DATA PRIVACY")}
                        link={Routes.privacy}
                      />,
                      <RouteLink
                        label={props.t("IMPRINT")}
                        link={Routes.imprint}
                      />,
                    ]}
                  />
                </Col>
              </>
            )}
          </Row>
        </Container>
      </MediaQuery>
      <MediaQuery maxDeviceWidth={MediaVariables.smallPage}>
        {!checkIfKioskRoute(props.site) && (
          <>
            <MobilePullDown
              key="mobilePullDown-1"
              headerLabel={props.t("OFFERS PORTAL")}
              values={[
                <RouteLink
                  label={props.t("SERVICES")}
                  link={Routes.services}
                />,
                <RouteLink
                  label={props.t("PRODUCTS")}
                  link={Routes.products}
                />,
                <RouteLink
                  label={props.t("TOS - PYREXX SERVICES")}
                  link={Routes.tosforpyrexxservices}
                />,
                <RouteLink
                  label={props.t("TOS - PYREXX PRODUCT SALES")}
                  link={Routes.tosforpyrexxproductsales}
                />,
              ]}
            />
            <MobilePullDown
              key="mobilePullDown-2"
              headerLabel={props.t("SUPPORT")}
              values={[
                <RouteLink
                  label={props.t("CONTACT")}
                  link={Routes.contactinfo}
                />,
              ]}
            />
            <MobilePullDown
              key="mobilePullDown-3"
              headerLabel={props.t("NEWSLETTER")}
              values={[
                <RouteLink
                  label={props.t("NEWSLETTER REGISTRATION")}
                  link={Routes.newsletter}
                />,
              ]}
            />
            <MobilePullDown
              key="mobilePullDown-4"
              headerLabel={props.t("PYREXX GMBH")}
              values={[
                <SimpleLink
                  label={props.t("ABOUT US")}
                  url={Links.aboutUs}
                  target="_BLANK"
                />,
                <SimpleLink
                  label={Links.pyrexx.replace(/(^\w+:|^)\/\//, "")}
                  url={Links.pyrexx}
                  target="_BLANK"
                />,
                <RouteLink
                  label={props.t("DATA PRIVACY")}
                  link={Routes.privacy}
                />,
                <RouteLink label={props.t("IMPRINT")} link={Routes.imprint} />,
              ]}
            />
          </>
        )}
      </MediaQuery>
    </>
  );
};
export default withTranslation("translation")(LinksFooter);

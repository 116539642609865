import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import MediaQuery from "react-responsive";
import MediaVariables from "../styles/base/sharedVariables.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const MobilePullDown = (props) => {
  const [mobilePullDownOpen, setMobilePullDownOpen] = useState(false);
  return (
    <MediaQuery maxDeviceWidth={MediaVariables.smallPage}>
      <Container
        as="nav"
        className="mobile-pull-down"
        onClick={() => setMobilePullDownOpen(!mobilePullDownOpen)}
        fluid
      >
        <Row className="justify-content-between">
          <Col
            className="mobile-pull-down-label"
            as="h3"
            xs="auto"
            md="auto"
            lg="auto"
          >
            {props.headerLabel}
          </Col>
          <Col xs="auto" md="auto" lg="auto" className="text-right">
            {mobilePullDownOpen ? (
              <i className="fa fa-minus arrow-icon" aria-hidden="true" />
            ) : (
              <i className="fa fa-plus arrow-icon" aria-hidden="true" />
            )}
          </Col>
        </Row>
        <Row
          as="ul"
          className={
            "list-unstyled flex-column mobile-pull-down-values " +
            (mobilePullDownOpen ? "open" : "")
          }
        >
          {props.values.map((value, index) => (
            <Col
              as="li"
              xs={12}
              md={12}
              lg={12}
              key={props.key + "-" + index}
              className="pl-4"
            >
              {value}
            </Col>
          ))}
        </Row>
      </Container>
    </MediaQuery>
  );
};

export default withRouter(MobilePullDown);

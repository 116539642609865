import React, { useEffect } from "react";
import { QueryRenderer } from "react-relay";
import environment from "../../Environment";
import graphql from "babel-plugin-relay/macro";
import ParagraphInfo from "../../components/ParagraphInfo";
import { withTranslation } from "react-i18next";
import LoadingSpinner from "../../components/LoadingSpinner";
import Error from "../Error/Error";

const TosForPyrexxProductSalesQuery = graphql`
  query TosForPyrexxProductSalesQuery($lang: Language!) {
    TOSFORPYREXXPRODUCTSALES: tosSales(lang: $lang) {
      co_id
      date
      title
      paragraphs {
        number
        name
        sections {
          number
          name
          text
        }
      }
    }
  }
`;

const TosForPyrexxProductSales = (propsX) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const language = propsX.i18n.language.substring(0, 2).toUpperCase();
  return (
    <QueryRenderer
      environment={environment}
      variables={{ lang: language }}
      query={TosForPyrexxProductSalesQuery}
      render={({ error, props }) => {
          if (error) {
              console.log('error check0',error)
              return <Error errorMessage={error.message} />;
          } else if (props && props?.errors) {
              console.log('error check1',props)
              return <Error errorMessage={props.errors.message} />;
          } else if (props && !props?.errors) {
          return (
            <ParagraphInfo
              title={propsX.t("TOS FOR PYREXX PRODUCT SALES")}
              showprefixtitle={false}
              content={props}
              {...propsX}
            />
          );
        }
        return <LoadingSpinner />;
      }}
    />
  );
};

export default withTranslation("translation")(TosForPyrexxProductSales);

export const Routes = {
  home: "/home",
  services: "/services",
  products: "/products",
  newsletter: "/registerNewsletter",
  newsletterevent: "/registerNewsletterEvent",
  requestWizard: "/requestWizard",
  serviceWizard: "/serviceWizard",
  contactinfo: "/contactinfo",
  termsofservice: "/termsofservice",
  tosversion: "/tosversion",
  tosforpyrexxservices: "/tosforpyrexxservices",
  tosforpyrexxproductsales: "/tosforpyrexxproductsales",
  privacy: "/privacy",
  imprint: "/imprint",
  confirmSub: "/confirmSubscription",
};

export const KioskRoutes = {
  newsletterevent: "/registerNewsletterEvent",
};

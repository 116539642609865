import React from "react";
import { withTranslation } from "react-i18next";
import RouteLink from "./RouteLink";
import RequestListInfo from "./RequestListInfo";
import OutsideClickHandler from "react-outside-click-handler";
import { Routes } from "../configs/RouteConfig";

const MobileMenu = (props) => {
  const openMenu = props.open ? "open" : "";
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        if (props.open) {
          props.toggleMobileMenu(false);
        }
      }}
    >
      <div className={"mobile-menu " + openMenu}>
        <div className="mobile-menu-wrapper">
          <div className="mobile-menu-header">
            <button
              className="close"
              onClick={() => {
                props.toggleMobileMenu(false);
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <RequestListInfo
              label="REQUEST LIST"
              currentCard={props.currentCard}
              handleShowPqpCardMenu={props.handleShowPqpCardMenu}
            />
          </div>
          <hr width="100%" align="center" />

          <RouteLink
            onClick={() => {
              props.toggleMobileMenu(false);
            }}
            label={props.t("SERVICES")}
            addClassName="link-decoration link-color-primary"
            link={Routes.services}
          />
          <RouteLink
            onClick={() => {
              props.toggleMobileMenu(false);
            }}
            label={props.t("PRODUCTS")}
            addClassName="link-decoration link-color-primary"
            link={Routes.products}
          />
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default withTranslation("translation")(MobileMenu);

const getServices = (t = (v) => v) => {
  // List of services shown in /services
  return [
    {
      id: 1, // service id (primary key unique for each service)
      title: t("SMOKE DETECTOR COMPLETE SERVICE FOR THE HOUSING INDUSTRY"), // set the title in the provider box (overview)
      description: t(
        "AS A NATIONWIDE SPECIALIST SERVICE PROVIDER AND GERMAN MANUFACTURER OF QUALITY SMOKE DETECTORS, WE RELY ON THE HIGHEST CUSTOMER SATISFACTION ON FAIR TERMS. WHETHER SERVICES INCLUDING PYREXX DEVICES OR INSPECTION OF THIRD-PARTY DEVICES, WE OFFER THE COMPLETE RANGE OF SERVICES FROM A SINGLE SOURCE, EXCLUSIVELY WITH PERMANENT EMPLOYEES."
      ), // set the description in the provider box
      images: [
        // defines the images which is in the carousel from the provider box
        {
          id: 4, // Image id (primary key unique for each carousel)
          urlMiddle: "/img/smoke_detector.jpg", // url from the image
        },
      ],
      definedContainer: [2, 2, 1], // This defines the order and amount of the div container in the second contact wizard step. e.g. [1,2,1] Mean 3 lines with 1, 2 and 1 div container on each line
      wizardSteps: {
        // These are the wizard steps when a user selected a service
        1: {
          title: "",
          description: t("CHOOSE A PRODUCT."),
          nextStep: 4,
          controls: [
            {
              id: 1,
              type: "radio",
              line: 1,
              container: 2,
              required: true,
              paramName: "ignored", // This co key doesn't exists! It will be ignored in backend!
              componentName: "imageTiles",
              options: {
                label: t("SMOKE DETECTOR TYPE"),
                options: [
                  // Component/Extra options. In this case it define the images which is used for the 'imageTiles'
                  {
                    id: 1, // imageTile id (primary key unique for each imageTile)
                    images: [
                      // Define the images which is used in imageTile
                      {
                        id: 1, // image id (primary key unique image in the images array)
                        urlMiddle: "/img/Produkte_PX-1_weiss.jpg", // Image url
                      },
                    ],
                    name: "PX-1", // Set the Label/Title for the imageTile
                    description: t(
                      "THE CLASSIC FROM PYREXX FOR THE HOUSING INDUSTRY, DURABLE, PROVEN, SAFE."
                    ), // Set the description for the ImageTile
                    overrideNextStep: 2, // This is a special param for 'ImageTiles' which is over ride the nextStep id, if the user decide to select the option. It is helpful to create a custom wizardStep order
                  },
                  {
                    id: 2,
                    images: [
                      {
                        id: 2,
                        urlMiddle: "/img/Produkte_PX-1C_weiss.jpg",
                      },
                    ],
                    name: "PX-1C",
                    description: t(
                      "THE SPECIALIST WITH RADIO NETWORKING FOR SPECIAL EQUIPMENT, STAIRCASES AND NETWORKED OBJECTS."
                    ),
                    overrideNextStep: 3, // This is a special param for 'ImageTiles' which is over ride the nextStep id, if the user decide to select the option. It is helpful to create a custom wizardStep order
                  },
                  {
                    id: 3,
                    images: [
                      {
                        id: 3,
                        urlMiddle: "/img/type-c_smoke-detector.jpg", // Image url
                      },
                    ],
                    // Typ C Rauchwarnmelder mit Ferninspektion
                    name: t("TYPE C TITLE"),
                    description: t("TYPE C DESCRIPTION"),
                    overrideNextStep: 7, // Step 2 and 3 looks like they are quite similar, difference is in the ids of the options.
                  },
                ],
              },
            },
          ],
        },
        2: {
          title: t("YOUR SERVICE OPTIONS"), // Each wizard step has a title if its not empty
          description: t("(MULTIPLE CHOICE POSSIBLE)"), // Each wizard step has a description if its not empty
          descriptionPopup: [
            // If you want a info button right next to the title of the wizard step. It will open a simple popup with a defined text inside a array
            {
              header: t("PURCHASE, INSTALLATION & MAINTENANCE"), // Every popup section has a title(header)
              value: t(
                "TAKE ADVANTAGE OF OUR MOST CHOSEN SERVICE OPTION, CONSISTING OF EQUIPMENT PURCHASE OF OUR SMOKE DETECTORS MANUFACTURED IN GERMANY AND THE ASSOCIATED FULL-SCALE INSPECTION AND MAINTENANCE SERVICE ACCORDING TO DIN 14676."
              ), /// /Every popup section has a description(value)
            },
            {
              header: t("RENT, INSTALLATION & MAINTENANCE"),
              value: t(
                "TAKE ADVANTAGE OF OUR ALL-ROUND CAREFREE PACKAGE AND RECEIVE OUR QUALITY SMOKE DETECTOR WITH RENTAL OPTION AND THE ASSOCIATED FULL-SERVICE MAINTENANCE."
              ),
            },
            {
              header: t("PURCHASE & ASSEMBLY"),
              value: t(
                "YOU WOULD LIKE TO BUY OUR EQUIPMENT AND HAVE IT PROFESSIONALLY INSTALLED IN ACCORDANCE WITH DIN 14676, BUT YOU CAN TAKE OVER THE MAINTENANCE YOURSELF OR YOU CAN NOT DECIDE AT THE MOMENT? THEN THIS OPTION IS RIGHT FOR YOU!"
              ),
            },
          ],
          nextStep: 4, // This defines the next step when the user pressed the next button. The number should be the the next desired wizardStep id
          controls: [
            // The controls includes all the form input and other user interactions from components for an single wizard step
            {
              id: 1, // Control id (primary key unique for each controls)
              line: 1, // This define in which line the user see the selected/typed information on contactWizardStep 2
              container: 1, // This define in which container (order from left to right) the user see the selected/typed information on contactWizardStep 2
              type: "checkbox", // Defines the type of used form input/user interaction
              paramName: "pc_ids", // Defines the paramName which is used in the backend. Its important and should be decided from the backend [BACKEND INFO]
              required: true, // This defines if the input is required before the next step is available
              isLongTile: false, // This is a special param for the 'Tiles' component, which decide if the tiles long, default are small tiles.
              setLg: 4, // This is a special param for the 'Tiles' component, which decide how many tiles are shown in one line. It describes the order rule from bootstrap container (X/12 = how many items per line)
              componentName: "Tiles", // This define the used component. Default means, a normal bootstrap form input will be used dependent which 'type' is set
              options: {
                // Define options for the input
                label: t("SERVICE"), // Define the label for the input field
                options: [
                  // Component/Extra options. In this case it define tiles for the 'Tiles' component.
                  {
                    id: 60, // options id (primary key unique for each options). [BACKEND INFO]
                    name: t("PURCHASE, INSTALLATION & MAINTENANCE"), // Description for the checkbox/radio tile
                  },
                  {
                    id: 61,
                    name: t("RENT, INSTALLATION & MAINTENANCE"),
                  },
                  {
                    id: 59,
                    name: t("PURCHASE & ASSEMBLY"),
                  },
                ],
              },
            },
          ],
        },
        3: {
          title: t("YOUR SERVICE OPTIONS"), // Each wizard step has a title if its not empty
          description: t("(MULTIPLE CHOICE POSSIBLE)"), // Each wizard step has a description if its not empty
          descriptionPopup: [
            // If you want a info button right next to the title of the wizard step. It will open a simple popup with a defined text inside a array
            {
              header: t("PURCHASE, INSTALLATION & MAINTENANCE"), // Every popup section has a title(header)
              value: t(
                "TAKE ADVANTAGE OF OUR MOST CHOSEN SERVICE OPTION, CONSISTING OF EQUIPMENT PURCHASE OF OUR SMOKE DETECTORS MANUFACTURED IN GERMANY AND THE ASSOCIATED FULL-SCALE INSPECTION AND MAINTENANCE SERVICE ACCORDING TO DIN 14676."
              ), /// /Every popup section has a description(value)
            },
            {
              header: t("RENT, INSTALLATION & MAINTENANCE"),
              value: t(
                "TAKE ADVANTAGE OF OUR ALL-ROUND CAREFREE PACKAGE AND RECEIVE OUR QUALITY SMOKE DETECTOR WITH RENTAL OPTION AND THE ASSOCIATED FULL-SERVICE MAINTENANCE."
              ),
            },
            {
              header: t("PURCHASE & ASSEMBLY"),
              value: t(
                "YOU WOULD LIKE TO BUY OUR EQUIPMENT AND HAVE IT PROFESSIONALLY INSTALLED IN ACCORDANCE WITH DIN 14676, BUT YOU CAN TAKE OVER THE MAINTENANCE YOURSELF OR YOU CAN NOT DECIDE AT THE MOMENT? THEN THIS OPTION IS RIGHT FOR YOU!"
              ),
            },
          ],
          nextStep: 5, // This defines the next step when the user pressed the next button. The number should be the the next desired wizardStep id
          controls: [
            // The controls includes all the form input and other user interactions from components for an single wizard step
            {
              id: 1, // Control id (primary key unique for each controls)
              line: 1, // This define in which line the user see the selected/typed information on contactWizardStep 2
              container: 1, // This define in which container (order from left to right) the user see the selected/typed information on contactWizardStep 2
              type: "checkbox", // Defines the type of used form input/user interaction
              paramName: "pc_ids", // Defines the paramName which is used in the backend. Its important and should be decided from the backend [BACKEND INFO]
              required: true, // This defines if the input is required before the next step is available
              isLongTile: false, // This is a special param for the 'Tiles' component, which decide if the tiles long, default are small tiles.
              setLg: 4, // This is a special param for the 'Tiles' component, which decide how many tiles are shown in one line. It describes the order rule from bootstrap container (X/12 = how many items per line)
              componentName: "Tiles", // This define the used component. Default means, a normal bootstrap form input will be used dependent which 'type' is set
              options: {
                // Define options for the input
                label: t("SERVICE"), // Define the label for the input field
                options: [
                  // Component/Extra options. In this case it define tiles for the 'Tiles' component.
                  {
                    id: 63, // options id (primary key unique for each options). [BACKEND INFO]
                    name: t("PURCHASE, INSTALLATION & MAINTENANCE"), // Description for the checkbox/radio tile
                  },
                  {
                    id: 64,
                    name: t("RENT, INSTALLATION & MAINTENANCE"),
                  },
                  {
                    id: 62,
                    name: t("PURCHASE & ASSEMBLY"),
                  },
                ],
              },
            },
          ],
        },
        4: {
          title: "",
          description: "",
          nextStep: 6,
          controls: [
            {
              id: 1,
              type: "number",
              required: true,
              line: 2,
              container: 1,
              paramName: "co_number_ne",
              componentName: "default",
              options: {
                label: t("HOW MANY USAGE UNITS SHOULD BE SERVICED"), // Define the label for the default form intput (text)
                value: "", // Define the default valie for the default form intput (text)
              },
            },
            {
              id: 2,
              type: "number",
              required: true,
              line: 2,
              container: 1,
              paramName: "co_number_px1",
              componentName: "default",
              options: {
                label: t("HOW MANY SMOKE DETECTORS ARE NEEDED"),
                value: "",
              },
            },
            {
              id: 3,
              type: "text",
              required: true,
              line: 2,
              container: 2,
              paramName: "co_ne_zip_area",
              componentName: "default",
              options: {
                label: t("TOWN / POSTCODE - AREA / OBJECT"),
                value: "",
              },
            },
            {
              id: 4,
              type: "date",
              line: 2,
              container: 2,
              paramName: "co_begin",
              required: true,
              componentName: "default",
              options: {
                label: t("CONTRACT AND SERVICE START"), // Define the label for the default form intput (date)
                value: "", // Define the default date for the default form intput (date)
              },
            },
          ],
        },
        5: {
          title: "",
          description: "",
          nextStep: 6,
          controls: [
            {
              id: 1,
              type: "number",
              required: true,
              line: 2,
              container: 1,
              paramName: "co_number_ne",
              componentName: "default",
              options: {
                label: t("HOW MANY USAGE UNITS SHOULD BE SERVICED"), // Define the label for the default form intput (text)
                value: "", // Define the default valie for the default form intput (text)
              },
            },
            {
              id: 2,
              type: "number",
              required: true,
              line: 2,
              container: 1,
              paramName: "co_number_px1c",
              componentName: "default",
              options: {
                label: t("HOW MANY SMOKE DETECTORS ARE NEEDED"),
                value: "",
              },
            },
            {
              id: 3,
              type: "text",
              required: true,
              line: 2,
              container: 2,
              paramName: "co_ne_zip_area",
              componentName: "default",
              options: {
                label: t("TOWN / POSTCODE - AREA / OBJECT"),
                value: "",
              },
            },
            {
              id: 4,
              type: "date",
              line: 2,
              container: 2,
              paramName: "co_begin",
              required: true,
              componentName: "default",
              options: {
                label: t("CONTRACT AND SERVICE START"), // Define the label for the default form intput (date)
                value: "", // Define the default date for the default form intput (date)
              },
            },
          ],
        },
        6: {
          title: "",
          description: "",
          nextStep: false,
          controls: [
            {
              id: 1,
              line: 3,
              container: 1,
              type: "textarea",
              paramName: "quo_text",
              componentName: "default",
              options: {
                showLabel: false, // Define if the label will be shown at the 'contactWizardStep2' step
                label: t("PLEASE GIVE US MORE INFORMATION ABOUT YOUR REQUEST."), // Define the label for an default form input (textarea)
              },
            },
          ],
        },
        7: {
          title: t("YOUR SERVICE OPTIONS"), // Each wizard step has a title if its not empty
          description: t("(MULTIPLE CHOICE POSSIBLE)"), // Each wizard step has a description if its not empty
          descriptionPopup: [
            // If you want a info button right next to the title of the wizard step. It will open a simple popup with a defined text inside a array
            {
              header: t("PURCHASE, INSTALLATION & MAINTENANCE"), // Every popup section has a title(header)
              value: t(
                "TAKE ADVANTAGE OF OUR MOST CHOSEN SERVICE OPTION, CONSISTING OF EQUIPMENT PURCHASE OF OUR SMOKE DETECTORS MANUFACTURED IN GERMANY AND THE ASSOCIATED FULL-SCALE INSPECTION AND MAINTENANCE SERVICE ACCORDING TO DIN 14676."
              ), /// /Every popup section has a description(value)
            },
            {
              header: t("RENT, INSTALLATION & MAINTENANCE"),
              value: t(
                "TAKE ADVANTAGE OF OUR ALL-ROUND CAREFREE PACKAGE AND RECEIVE OUR QUALITY SMOKE DETECTOR WITH RENTAL OPTION AND THE ASSOCIATED FULL-SERVICE MAINTENANCE."
              ),
            },
          ],
          nextStep: 4, // This defines the next step when the user pressed the next button. The number should be the the next desired wizardStep id
          controls: [
            // The controls includes all the form input and other user interactions from components for an single wizard step
            {
              id: 1, // Control id (primary key unique for each controls)
              line: 1, // This define in which line the user see the selected/typed information on contactWizardStep 2
              container: 1, // This define in which container (order from left to right) the user see the selected/typed information on contactWizardStep 2
              type: "checkbox", // Defines the type of used form input/user interaction
              paramName: "pc_ids", // Defines the paramName which is used in the backend. Its important and should be decided from the backend [BACKEND INFO]
              required: true, // This defines if the input is required before the next step is available
              isLongTile: false, // This is a special param for the 'Tiles' component, which decide if the tiles long, default are small tiles.
              setLg: 4, // This is a special param for the 'Tiles' component, which decide how many tiles are shown in one line. It describes the order rule from bootstrap container (X/12 = how many items per line)
              componentName: "Tiles", // This define the used component. Default means, a normal bootstrap form input will be used dependent which 'type' is set
              options: {
                // Define options for the input
                label: t("SERVICE"), // Define the label for the input field
                options: [
                  // Component/Extra options. In this case it define tiles for the 'Tiles' component.
                  {
                    id: 57,
                    name: t("TYPE C PURCHASE WITH SERVICE INSPECTION"),
                  },
                  {
                    id: 58, // options id (primary key unique for each options). [BACKEND INFO]
                    name: t("TYPE C RENT WITH SERVICE INSPECTION"), // Description for the checkbox/radio tile
                  },
                ],
              },
            },
          ],
        },
      },
    },
    {
      id: 2,
      title: t("DRINKING WATER ANALYSIS"),
      description: t(
        "WITH PROFESSIONAL TENANT MANAGEMENT AND SCHEDULING, PYREXX FULLY SUPPORTS YOU AS THE OWNER IN FULFILLING THE LEGAL OBLIGATIONS OF DRINKING WATER TESTING. IN ADDITION, WE ASSIST YOU IN CASE OF CONTAMINATION WITH ESCALATION MEASURES."
      ),
      images: [
        {
          id: 4,
          urlMiddle: "/img/drinking_water_analysis.jpg",
        },
      ],
      definedContainer: [1, 1, 1],
      wizardSteps: {
        1: {
          title: t("YOUR SERVICE OPTIONS"),
          description: "",
          nextStep: 2,
          controls: [
            {
              id: 1,
              line: 1,
              container: 1,
              type: "radio",
              paramName: "pc_ids",
              required: true,
              componentName: "Tiles",
              isLongTile: true,
              options: {
                label: t("SERVICE"),
                options: [
                  /**
                   {
										id: 26,
										overrideNextStep: 2, //This is a special param for 'Tiles' which is over ride the nextStep id, if the user decide to select the option. It is helpful to create a custom wizardStep order
										name: t("PREVENTION")
									},
                   **/
                  {
                    id: 34,
                    overrideNextStep: 4,
                    name: t("DRINKING WATER ANALYZES"),
                    additionalInfo: "",
                    selected: true,
                  },
                ],
              },
            },
            {
              id: 2,
              type: "text",
              required: false,
              line: 1,
              container: 1,
              paramName: "ignored",
              componentName: "SimpleNote",
              options: {
                label: "",
                // eslint-disable-next-line
                value: t(
                  "BY ORDERING THE SERVICE, AN ORDER WITH SYNLAB ANALYTICS & SERVICES GERMANY GMBH IS CONCLUDED."
                ),
              },
            },
          ],
        },
        2: {
          title: t("PREVENTION"),
          description: "",
          nextStep: 7,
          controls: [
            {
              id: 1,
              line: 2,
              container: 1,
              paramName: "co_number_ls",
              type: "number",
              required: true,
              componentName: "default",
              options: {
                label: t("NUMBER OF PROPERTIES"),
                value: "",
              },
            },
            {
              id: 2,
              line: 2,
              container: 1,
              type: "text",
              paramName: "co_ne_zip_area",
              required: true,
              componentName: "default",
              options: {
                label: t("TOWN / POSTCODE - AREA / OBJECT"),
                value: "",
              },
            },
            {
              id: 3,
              line: 2,
              container: 1,
              type: "date",
              paramName: "co_begin",
              required: true,
              componentName: "default",
              options: {
                label: t("START OF CONTRACT AND SERVICE"),
                value: "",
              },
            },
          ],
        },

        3: {
          title: t("DRINKING WATER ANALYZES"),
          description: "",
          nextStep: 4,
          controls: [
            {
              id: 1,
              line: 2,
              container: 1,
              type: "checkbox",
              paramName: "cpm_",
              componentName: "default",
              addCssClass: "flex-row-reverted",
              showValues: false,
              options: {
                id: 1,
                label: t("LEGIONELLA TESTING"),
                value: t("YES"),
                negativeValue: t("NO"),
              },
            },
            {
              id: 2,
              line: 2,
              container: 1,
              type: "checkbox",
              paramName: "co_sensorische_pruefung_yesno",
              componentName: "default",
              addCssClass: "flex-row-reverted",
              showValues: false,
              options: {
                id: 2,
                label: t("SENSORY EXAMINATION"),
                value: t("YES"),
                negativeValue: t("NO"),
              },
            },
            {
              id: 3,
              line: 2,
              container: 1,
              type: "checkbox",
              paramName: "co_mikrobiologische_und_chemische_untersuchung_yesno",
              componentName: "default",
              addCssClass: "flex-row-reverted",
              showValues: false,
              options: {
                id: 3,
                label: t("MICROBIOLOGICAL AND CHEMICAL ANALYSIS"),
                value: t("YES"),
                negativeValue: t("NO"),
              },
            },
          ],
        },
        4: {
          title: t("DRINKING WATER ANALYZES"),
          description: "",
          nextStep: 7,
          controls: [
            {
              id: 1,
              line: 2,
              container: 1,
              paramName: "co_number_ls",
              type: "number",
              required: true,
              componentName: "default",
              options: {
                label: t("NUMBER OF PROPERTIES"),
                value: "",
              },
            },
            {
              id: 2,
              line: 2,
              container: 1,
              paramName: "co_number_samples",
              type: "number",
              required: true,
              componentName: "default",
              options: {
                label: t("NUMBER OF SAMPLES"),
                value: "",
              },
            },
            {
              id: 3,
              line: 2,
              container: 1,
              paramName: "co_ne_zip_area",
              type: "text",
              required: true,
              componentName: "default",
              options: {
                label: t("TOWN / POSTCODE - AREA / OBJECT"),
                value: "",
              },
            },
            {
              id: 4,
              line: 2,
              container: 1,
              type: "date",
              paramName: "co_begin",
              required: true,
              componentName: "default",
              options: {
                label: t("START OF CONTRACT AND SERVICE"),
                value: "",
              },
            },
          ],
        },

        7: {
          title: "",
          description: "",
          nextStep: false,
          controls: [
            {
              id: 1,
              line: 3,
              container: 1,
              type: "textarea",
              paramName: "quo_text",
              componentName: "default",
              options: {
                showLabel: false,
                label: t("PLEASE GIVE US MORE INFORMATION ABOUT YOUR REQUEST."),
              },
            },
          ],
        },
      },
    },
    {
      id: 3,
      title: t("GRAFFITI REMOVAL AND SURFACE CLEANING"),
      description: t(
        "THE FACADE OF A HOUSE IS THE CALLING CARD OF YOUR PROPERTY. THANKS TO INNOVATIVE CLEANING CONCEPTS INCLUDING LONG-LASTING SEALING PYREXX ENSURES CLEANLINESS AND VALUE RETENTION."
      ),
      images: [
        {
          id: 4,
          urlMiddle: "/img/graffiti_removal.jpg",
        },
      ],
      definedContainer: [2, 2, 1],
      wizardSteps: {
        1: {
          title: t("YOUR SERVICE OPTIONS"),
          description: t("(MULTIPLE CHOICE POSSIBLE)"),
          nextStep: 2,
          controls: [
            {
              id: 1,
              line: 1,
              container: 1,
              type: "checkbox",
              paramName: "pc_ids",
              required: true,
              isLongTile: false,
              componentName: "Tiles",
              options: {
                label: t("SERVICE"),
                showLabel: true,
                options: [
                  {
                    id: 29,
                    name: t("GRAFFITI REMOVAL"),
                  },
                  {
                    id: 30,
                    name: t("SURFACE CLEANING"),
                  },
                  {
                    id: 28,
                    name: t("SURFACE SEALING"),
                  },
                ],
              },
            },
          ],
        },
        2: {
          title: "",
          description: "",
          nextStep: 3,
          controls: [
            {
              id: 1,
              line: 2,
              container: 1,
              paramName: "co_job_site",
              type: "text",
              popupInfoText: t(
                "HERE YOU CAN SPECIFIY FURTHER PLACES OF ACTION."
              ),
              required: true,
              componentName: "default",
              options: {
                label: t("ADDRESS OF PLACE OF ACTION"),
                value: "",
              },
            },
            {
              id: 2,
              line: 2,
              container: 1,
              type: "text",
              paramName: "co_kind_of_area",
              required: true,
              componentName: "default",
              options: {
                label: t("TYPE OF AREA (WALL, STAIRS, DOOR, ETC.)"),
                value: "",
              },
            },
            {
              id: 3,
              line: 2,
              container: 1,
              type: "text",
              paramName: "co_kind_of_substrate",
              required: true,
              componentName: "default",
              options: {
                label: t("SURFACE (CLINKER, GRANITE, PLASTER, ETC.)"),
                value: "",
              },
            },
            {
              id: 4,
              line: 2,
              container: 1,
              type: "text",
              paramName: "co_contamination_type",
              required: true,
              componentName: "default",
              options: {
                label: t(
                  "CONTAMINATION TYPE (GRAFFITI, SALTPETRE, MOSS, ETC.)"
                ),
                value: "",
              },
            },
          ],
        },
        3: {
          title: "",
          description: "",
          nextStep: 4,
          controls: [
            {
              id: 1,
              line: 2,
              container: 2,
              type: "number",
              paramName: "co_cleaning_area_qm",
              required: true,
              componentName: "default",
              options: {
                label: t("AREA TO CLEAN (SQM)"),
                value: "",
              },
            },
            {
              id: 2,
              line: 2,
              container: 2,
              type: "number",
              paramName: "co_work_height",
              required: true,
              componentName: "default",
              options: {
                label: t("MAX. WORKING HEIGHT (M)"),
                value: "",
              },
            },
            {
              id: 3,
              line: 2,
              container: 2,
              type: "checkbox",
              popupInfoText: t(
                "IF THERE IS NO POWER SOCKET, ADDITIONAL COSTS MAY BE INVOICED."
              ),
              paramName: "co_power_available",
              componentName: "default",
              options: {
                id: 1, // checkbox id number (int)
                label: t("POWER SOCKET FREELY ACCESSIBLE IN MAX. 20M DISTANCE"), // Title for the default input checkbox (Will be shown over the checkbox)
                value: t("YES"), // Label for the default input checkbox(Will be shown right next to the checkbox). This answer will be also shown in the 'wizardContactStep2' if selected
                negativeValue: t("NO"), // This will be shown in 'wizardContactStep2' if the user didnt selected the checkbox
              },
            },
            {
              id: 4,
              line: 2,
              container: 2,
              type: "fileupload",
              paramName: "files",
              componentName: "default",
              options: {
                supportedFileTypes: "image/*, application/pdf",
                maxsizeKb: 5000,
                multiple: true,
                label: t(
                  "PLEASE PROVIDE US WITH A PICTURE OF THE SURFACE CONTAMINATION (MAX 5MB TOTAL)"
                ),
                value: "",
              },
            },
          ],
        },
        4: {
          title: "",
          description: "",
          nextStep: false,
          controls: [
            {
              id: 1,
              line: 3,
              container: 1,
              type: "textarea",
              paramName: "quo_text",
              componentName: "default",
              options: {
                showLabel: false,
                label: t("PLEASE GIVE US MORE INFORMATION ABOUT YOUR REQUEST."),
              },
            },
          ],
        },
      },
    },
    {
      id: 4,
      replaceParams: {
        // This will be over ride/default value the paramName in the object which will be sent to the backend (It is a primary over ride)
        pc_ids: [65], // This means the pc_ids will be over ride.
      },
      title: t("MAINTENANCE OF 3RD PARTY SMOKE DETECTORS"),
      description: t(
        "YOU HAVE ALREADY INSTALLED SMOKE DETECTORS AND WOULD NOW LIKE A (NEW) SERVICE PROVIDER TO TAKE OVER THE PROFESSIONAL INSPECTION AND MAINTENANCE SERVICE ACCORDING TO DIN 14676? PYREXX IS YOUR PARTNER!"
      ),
      images: [
        {
          id: 4,
          urlMiddle: "/img/foreign_maintenance.jpg",
        },
      ],
      definedContainer: [2, 1],
      wizardSteps: {
        1: {
          title: "",
          description: "",
          nextStep: 2,
          controls: [
            {
              id: 1,
              type: "number",
              required: true,
              line: 1,
              container: 1,
              paramName: "co_number_ne",
              componentName: "default",
              options: {
                label: t(
                  "HOW MANY USAGE UNITS (INCLUDING COMMON SPACES) SHOULD BE EQUIPPED"
                ),
                value: "",
              },
            },
            {
              id: 2,
              type: "number",
              required: true,
              line: 1,
              container: 1,
              paramName: "co_number_sa_foreign",
              componentName: "default",
              options: {
                label: t("HOW MANY SMOKE DETECTORS SHOULD BE SERVICED"),
                value: "",
              },
            },
            {
              id: 3,
              line: 1,
              container: 2,
              type: "text",
              paramName: "co_ne_zip_area",
              required: true,
              componentName: "default",
              options: {
                label: t("TOWN / POSTCODE - AREA / OBJECT"),
                value: "",
              },
            },
            {
              id: 4,
              type: "date",
              line: 1,
              container: 2,
              paramName: "co_begin",
              componentName: "default",
              options: {
                label: t("START OF CONTRACT AND SERVICE"),
                value: "",
              },
            },
          ],
        },
        2: {
          title: "",
          description: "",
          nextStep: false,
          controls: [
            {
              id: 1,
              line: 2,
              container: 1,
              type: "textarea",
              paramName: "quo_text",
              componentName: "default",
              options: {
                showLabel: false,
                label: t("DO YOU HAVE FURTHER QUESTIONS OR COMMENTS?"),
              },
            },
          ],
        },
      },
    },
    // SERVICE REMOVED. STAYS HERE BECAUSE OF DOCUMENTATION REASONS
    // {
    //   id: 5,
    //   definedContainer: [2, 2, 1],
    //   title: t("NETWORKED SECURITY SOLUTIONS"),
    //   description: t(
    //     "WHETHER RESIDENTIAL HOMES, ASSISTED LIVING AND DAYCARE, WHERE FIRE ALARM SYSTEMS ARE NOT MANDATORY, PYREXX OFFERS THE IDEAL SUPPLEMENT FOR VARIOUS FIRE PROTECTION REQUIREMENTS. THROUGH WIRELESS NETWORKING AND ONLINE FORWARDING, WE MEET THE INCREASED SECURITY REQUIREMENTS OF A WIDE VARIETY OF HOME CONCEPTS."
    //   ),
    //   images: [
    //     {
    //       id: 1,
    //       urlMiddle: "/img/networked_security_pxip_gateway.jpg",
    //     },
    //   ],
    //   wizardSteps: {
    //     1: {
    //       title: t("YOUR SERVICE OPTIONS"),
    //       description: "",
    //       position: 1,
    //       nextStep: 2,
    //       controls: [
    //         {
    //           id: 1,
    //           line: 1,
    //           container: 1,
    //           type: "radio",
    //           paramName: "pc_ids",
    //           required: true,
    //           isLongTile: true,
    //           setLg: 12,
    //           componentName: "Tiles",
    //           options: {
    //             label: t("SERVICE"),
    //             options: [
    //               {
    //                 id: 33,
    //                 name: t("PURCHASE, INSTALLATION AND SERVICE"),
    //               } /**
    //                {
    //               id: 32,
    //               name: t("PURCHASE & ASSEMBLY"),
    //             },**/,
    //             ],
    //           },
    //         },
    //       ],
    //     },
    //     2: {
    //       title: "",
    //       description: "",
    //       nextStep: 3,
    //       controls: [
    //         {
    //           id: 1,
    //           type: "number",
    //           required: true,
    //           line: 2,
    //           container: 1,
    //           paramName: "co_number_ne",
    //           componentName: "default",
    //           options: {
    //             label: t(
    //               "HOW MANY USAGE UNITS (INCLUDING COMMON SPACES) SHOULD BE EQUIPPED"
    //             ),
    //             value: "",
    //           },
    //         },
    //         {
    //           id: 2,
    //           type: "number",
    //           required: true,
    //           line: 2,
    //           container: 1,
    //           paramName: "co_number_px1c",
    //           componentName: "default",
    //           options: {
    //             label: t("HOW MANY SMOKE DETECTORS ARE NEEDED"),
    //             value: "",
    //           },
    //         },
    //       ],
    //     },
    //     3: {
    //       title: "",
    //       description: "",
    //       nextStep: 4,
    //       controls: [
    //         {
    //           id: 1,
    //           type: "text",
    //           required: true,
    //           line: 2,
    //           container: 2,
    //           paramName: "co_ne_zip_area",
    //           componentName: "default",
    //           options: {
    //             label: t("TOWN / POSTCODE - AREA / OBJECT"),
    //             value: "",
    //           },
    //         },
    //         {
    //           id: 2,
    //           type: "date",
    //           required: true,
    //           line: 2,
    //           container: 2,
    //           paramName: "co_begin",
    //           componentName: "default",
    //           options: {
    //             label: t("START OF CONTRACT AND SERVICE"),
    //             value: "",
    //           },
    //         },
    //         {
    //           id: 3,
    //           line: 2,
    //           container: 2,
    //           type: "checkbox",
    //           paramName: "co_internet_available",
    //           componentName: "default",
    //           options: {
    //             id: 1,
    //             label: t("INTERNET CONNECTION PRESENT"),
    //             value: t("YES"),
    //             negativeValue: t("NO"),
    //           },
    //         },
    //         {
    //           id: 4,
    //           line: 2,
    //           container: 2,
    //           type: "fileupload",
    //           paramName: "files",
    //           componentName: "default",
    //           options: {
    //             supportedFileTypes: "image/*, application/pdf",
    //             maxsizeKb: 5000,
    //             multiple: true,
    //             label: t(
    //               "PLEASE PROVIDE US WITH FURTHER DOCUMENTS (SPECIFICATIONS BUILDING AUTHORITY, BUILDING PLANS, FIRE PROTECTION CONCEPTS). (MAX 5MB TOTAL)"
    //             ),
    //             value: "",
    //           },
    //         },
    //       ],
    //     },
    //     4: {
    //       title: "",
    //       description: "",
    //       nextStep: false,
    //       controls: [
    //         {
    //           id: 1,
    //           line: 3,
    //           container: 1,
    //           type: "textarea",
    //           paramName: "quo_text",
    //           componentName: "default",
    //           options: {
    //             showLabel: false,
    //             label: t("PLEASE GIVE US MORE INFORMATION ABOUT YOUR REQUEST."),
    //           },
    //         },
    //       ],
    //     },
    //   },
    // },
  ];
};

export default getServices;

import React, { useCallback, useState } from "react";
import { withTranslation } from "react-i18next";
import Image from "react-bootstrap/Image";

import * as Yup from "yup";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import StyledInput from "./StyledInput";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import SimpleLink from "./SimpleLink";

const CardListItem = (props) => {
  const [editMode, setEditMode] = useState(false);
  const saveInput = useCallback(
    (amount) => {
      const handlePqpCardUpdate = props.handlePqpCardUpdate;
      const productPackage = [
        {
          id: props.id,
          packageOption: props.packageOption,
          amount: amount,
        },
      ];
      handlePqpCardUpdate(productPackage, "change");
      setEditMode(false);
    },
    [props.handlePqpCardUpdate, props.id, props.packageOption]
  );

  const schema = Yup.object({
    amount: Yup.number()
      .min(
        props.minOrderAmount,
        props.t("MIN. QUANTITY {AMOUNT}", { AMOUNT: props.minOrderAmount })
      )
      .required(
        props.t("MIN. QUANTITY {AMOUNT}", { AMOUNT: props.minOrderAmount })
      ),
  });

  return (
    <Container fluid className="card-list-item py-2">
      <Row className="pb-2 label-3">
        <Col xs="auto" md="auto" lg="auto" className="p-0">
          {props.countNumber}
        </Col>
        <Col>{props.label}</Col>
      </Row>
      <Row className="card-list-item-content py-2 align-items-center">
        <Col xs={4} md={4} lg={4}>
          <Image src={props.imageUrl} fluid />
        </Col>
        {editMode ? (
          <Col>
            <Formik
              validationSchema={schema}
              onSubmit={(values, formikBag) => {
                saveInput(values.amount);
              }}
              initialValues={{
                amount: props.amount,
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                errors,
                touched,
                submitForm,
              }) => (
                <Form
                  id="stepServiceContact1Form"
                  noValidate
                  onSubmit={handleSubmit}
                  className="form-inline"
                >
                  <Row className="align-items-center">
                    <Col>
                      <StyledInput
                        type="text"
                        name="amount"
                        placeholder={props.t("AMOUNT")}
                        onChange={handleChange}
                        value={values.amount}
                        autoComplete="off"
                        onBlur={handleBlur}
                        touched={touched.amount}
                        error={errors.amount}
                      />
                    </Col>
                    <Col xs="auto" md="auto" lg="auto">
                      <div>
                        <SimpleLink
                          label={props.t("SAVE")}
                          onClick={() => {
                            submitForm();
                          }}
                        />
                      </div>
                      <div>
                        <SimpleLink
                          label={props.t("DELETE")}
                          onClick={() => {
                            const productPackage = [
                              {
                                id: props.id,
                                packageOption: props.packageOption,
                                amount: 0,
                              },
                            ];
                            props.handlePqpCardUpdate(productPackage, "change");
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        ) : (
          <>
            <Col>
              {props.amount} {props.t("PCS")}
            </Col>
            <Col xs="auto" md="auto" lg="auto">
              <SimpleLink
                label={<span>{props.t("EDIT")}</span>}
                onClick={() => {
                  setEditMode({
                    ...editMode,
                    editMode: true,
                  });
                }}
              />
            </Col>
          </>
        )}
      </Row>
    </Container>
  );
};

export default withTranslation("translation")(CardListItem);

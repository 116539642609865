import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import CardContainer from "../../components/CardContainer";
import { withRouter } from "react-router-dom";

const Error = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <CardContainer
      metaTitle={props.t("PQP_SITE_TITLE_ERROR")}
      siteTitle={props.t("SOMETHING WENT WRONG")}
      bodyContent={props.errorMessage}
    />
  );
};

export default withTranslation("translation")(withRouter(Error));

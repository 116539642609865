import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../Environment";

const mutation = graphql`
  mutation RegisterNewsletterEmailAndPersonMutation(
    $email: String!
    $name: String!
    $tagId: Int!
  ) {
    registerNewsletterEmailAndPerson(
      email: $email
      name: $name
      tag_id: $tagId
    ) {
      type
      message
    }
  }
`;

export default (email, name, tagId, callback) => {
  const variables = {
    email: email,
    name: name,
    tagId: tagId,
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (requestData) => {
      callback(requestData);
    },
    onError: (err) => console.error(err),
  });
};

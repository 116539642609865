import i18 from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const detectionOptions = {
  order: ["cookie", "navigator", "localStorage"],
};

i18
  .use(Backend)
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .use(LanguageDetector) // detect user language

  .init({
    backend: {
      // eslint-disable-next-line
      loadPath: "/locales/{lng}/LC_MESSAGES/{ns}.json" + "?cb=" + new Date().getTime(),
    },
    ns: ["translation", "agGridLocale"],
    defaultNS: "translation",
    detection: detectionOptions,
    whitelist: ["de_DE", "en_GB"],
    nsSeparator: ":::",
    keySeparator: ".::.",
    returnEmptyString: false,
    interpolation: {
      prefix: "{",
      suffix: "}",
      escapeValue: false,
    },
    fallbackLng: "en_GB",
    debug: false,
  });

export default i18;

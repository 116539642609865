import React from "react";
import { withTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import facebookThumbnail from "../assets/facebook_thumbnail.svg";
import xingThumbnail from "../assets/xing_thumbnail.svg";
import linkedinThumbnail from "../assets/linkedin_thumbnail.svg";
import youtubeThumbnail from "../assets/youtube_thumbnail.svg";
import { Links } from "../configs/LinkConfig";
import SimpleLink from "./SimpleLink";
import { checkIfKioskRoute } from "../utils/Helper";

const SocialFooter = (props) => {
  return (
    <Container className="p-3">
      <Row className="show-grid">
        <Col
          as="ul"
          xs={12}
          sm={12}
          className="d-flex list-unstyled justify-content-center"
        >
          {!checkIfKioskRoute(props.site) && (
            <>
              <li>
                <SimpleLink
                  className="no-underliner px-2"
                  label={
                    <Image
                      className="social-footer-link"
                      src={facebookThumbnail}
                    />
                  }
                  url={Links.facebook}
                  target="_BLANK"
                />
              </li>
              <li>
                <SimpleLink
                  className="no-underliner px-2"
                  noUnderline
                  label={
                    <Image className="social-footer-link" src={xingThumbnail} />
                  }
                  url={Links.xing}
                  target="_BLANK"
                />
              </li>
              <li>
                <SimpleLink
                  className="no-underliner px-2"
                  noUnderline
                  label={
                    <Image
                      className="social-footer-link"
                      src={linkedinThumbnail}
                    />
                  }
                  url={Links.linkedin}
                  target="_BLANK"
                />
              </li>
              <li>
                <SimpleLink
                  className="no-underliner px-2"
                  noUnderline
                  label={
                    <Image
                      className="social-footer-link"
                      src={youtubeThumbnail}
                    />
                  }
                  url={Links.youtube}
                  target="_BLANK"
                />
              </li>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};
export default withTranslation("translation")(SocialFooter);

import { KioskRoutes } from "../configs/RouteConfig";

const getSalutation = (salutationId) => {
  switch (parseInt(salutationId)) {
    case 1:
      return "MR";
    case 2:
      return "MRS";
    default:
      return null;
  }
};

const checkIfKioskRoute = (site) => {
  return Object.values(KioskRoutes).find((element) => element === "/" + site);
};

export { getSalutation, checkIfKioskRoute };

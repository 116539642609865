export const Links = {
  backToHomepage: "https://www.pyrexx.com",
  facebook: "https://de-de.facebook.com/Pyrexx.Group/",
  xing: "https://www.xing.com/companies/pyrexxgmbh",
  linkedin: "https://www.linkedin.com/company/pyrexx-group/",
  youtube: "https://www.youtube.com/channel/UC9DRy7sFpxjm3mqOXada98A",
  faq: "https://www.google.de",
  aboutUs:
    "https://www.pyrexx.com/de/unternehmen/%C3%BCber-uns/leitbilder-eines-verantwortlichen-unternehmertums",
  pyrexx: "https://www.pyrexx.com",
  legalNotice: "https://www.pyrexx.com/de/unternehmen/impressum",
  noImageAvailable: "/img/no-image.jpg",
  dataPrivacy: "https://www.pyrexx.com/de/unternehmen/impressum",
};

import React, { Component, Suspense } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Routes } from "../configs/RouteConfig";
import i18next from "i18next";
import en from "date-fns/locale/en-GB";
import de from "date-fns/locale/de";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import LoadingSpinner from "./LoadingSpinner";
import Layout from "./Layout";
import { compile, match } from "path-to-regexp";
import { Helmet } from "react-helmet";
import GA from "../utils/GoogleAnalytics";
import { CookieBanner, hasCookie } from "@palmabit/react-cookie-law";
import CustomCheckboxRadio from "./CustomCheckboxRadio";
import TosForPyrexxServices from "../routes/TosForPyrexxServices/TosForPyrexxServices";
import TosForPyrexxProductSales from "../routes/TosForPyrexxProductSales/TosForPyrexxProductSales";
import RegisterNewsletterEvent from "../routes/RegisterNewsletterEvent/RegisterNewsletterEvent";
import ConfirmSubscription from "../routes/ConfirmSubscription/ConfirmSubscription";
import getServices from "./services";

registerLocale("de_DE", de);
registerLocale("en_GB", en);

const NotFound = React.lazy(() => import("../routes/NotFound/NotFound"));
const ContactInfo = React.lazy(() =>
  import("../routes/contactinfo/ContactInfo")
);
const TermsOfService = React.lazy(() =>
  import("../routes/termsofservice/TermsOfService")
);
const TosVersion = React.lazy(() => import("../routes/tosversion/TosVersion"));
const PrivacyPolicy = React.lazy(() =>
  import("../routes/PrivacyPolicy/PrivacyPolicy")
);
const RequestWizard = React.lazy(() =>
  import("../routes/RequestWizard/RequestWizard")
);
const ServiceWizard = React.lazy(() =>
  import("../routes/ServiceWizard/ServiceWizard")
);
const RegisterNewsletter = React.lazy(() =>
  import("../routes/RegisterNewsletter/RegisterNewsletter")
);
const Imprint = React.lazy(() => import("../routes/Imprint/Imprint"));
const Products = React.lazy(() => import("../routes/Products/Products"));
const Services = React.lazy(() => import("../routes/Services/Services"));
const Home = React.lazy(() => import("../routes/Home/Home"));

class App extends Component {
  constructor(props) {
    super(props);
    this.firstCall = {
      req: false,
      pref: false,
      stats: false,
    };
    this.changeLanguage = this.changeLanguage.bind(this);
    this.state = {
      cookies: {
        req: hasCookie("consens"),
        pref: hasCookie("preferences"),
        stats: hasCookie("statistics"),
      },
      showPqpCardMenu: false,
      cardMenuIndex: 0,
      cardMenuDirection: null,
      pqpCard: this.handleLocalStorage("get", "pqpCard", null, {
        items: {},
        requestListCount: 0,
        totalAmount: 0,
        requestWasSent: false,
      }),
      pqpCurrentService: this.handleLocalStorage(
        "get",
        "pqpCurrentService",
        null,
        false
      ),
      pqpContactInformation: this.handleLocalStorage(
        "get",
        "pqpContactInformation",
        null,
        {
          customernumber: "",
          salutation: 0,
          firstname: "",
          lastname: "",
          email: "",
          telcountrycode: "",
          tel: "",
          company: "",
          business: "",
          jobpositions: "",
          street: "",
          housenumber: "",
          addressextra: "",
          zip: "",
          city: "",
          country: "",
          state: "",
        }
      ),
      messageBox: {
        visible: false,
        statusColorClass: "",
        message: "",
        updated: new Date(),
      },
    };
    this.handlePqpCardUpdate = this.handlePqpCardUpdate.bind(this);
    this.handleShowPqpCardMenu = this.handleShowPqpCardMenu.bind(this);
    this.handleCardMenuIndex = this.handleCardMenuIndex.bind(this);
    this.handleContactInformation = this.handleContactInformation.bind(this);
    this.handleCurrentService = this.handleCurrentService.bind(this);
    setDefaultLocale(this.props.i18n.language);
    this.handleLocalStorage = this.handleLocalStorage.bind(this);
    this.showMessageBox = this.showMessageBox.bind(this);
    this.closeMessageBox = this.closeMessageBox.bind(this);
  }

  localStorageAvailable() {
    if (!hasCookie("preferences")) {
      return false;
    }

    const test = true;
    try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    } catch (e) {
      return false;
    }
  }

  showMessageBox(message, statusColorClass) {
    this.setState(
      {
        messageBox: {
          visible: true,
          message,
          statusColorClass: statusColorClass,
          updated: new Date(),
        },
      },
      () => {
        //console.log("messagebox", this.state);
      }
    );
  }

  closeMessageBox() {
    this.setState({
      messageBox: {
        ...this.state.messageBox,
        visible: false,
      },
    });
  }

  handleLocalStorage(command, key, value, fallback) {
    if (this.localStorageAvailable()) {
      switch (command) {
        case "get":
          return localStorage.getItem(key)
            ? JSON.parse(localStorage.getItem(key))
            : fallback;
        case "set":
          localStorage.setItem(key, JSON.stringify(value));
          break;
        default:
      }
    } else {
      switch (command) {
        case "get":
          return sessionStorage.getItem(key)
            ? JSON.parse(sessionStorage.getItem(key))
            : fallback;
        case "set":
          sessionStorage.setItem(key, JSON.stringify(value));
          break;
        default:
      }
    }
  }

  handleCurrentService(serviceObject) {
    const currentLang = this.props.i18n.language.substring(0, 2).toLowerCase();
    this.props.history.replace("/" + currentLang + Routes.services);
    this.setState(
      (state) => {
        state.pqpCurrentService = serviceObject;
      },
      () => {
        this.handleLocalStorage(
          "set",
          "pqpCurrentService",
          this.state.pqpCurrentService
        );
        this.props.history.push("/" + currentLang + Routes.serviceWizard);
      }
    );
  }

  handleContactInformation(setPqpContactInformation) {
    this.setState(
      (state) => {
        state.pqpContactInformation = setPqpContactInformation;
      },
      () => {
        this.handleLocalStorage(
          "set",
          "pqpContactInformation",
          this.state.pqpContactInformation
        );
        this.forceUpdate();
      }
    );
  }

  handleCardMenuIndex(selectedIndex, e) {
    this.setState({
      cardMenuIndex: selectedIndex,
      cardMenuDirection: e.direction,
    });
  }

  handleShowPqpCardMenu(status) {
    this.setState({ showPqpCardMenu: status });
  }

  handlePqpCardUpdate(productPackages, action) {
    if (action === "deletePqpCard") {
      this.setState(
        {
          pqpCard: {
            items: {},
            requestListCount: 0,
            totalAmount: 0,
            requestWasSent: true,
          },
        },
        () => {
          this.handleLocalStorage("set", "pqpCard", this.state.pqpCard);
        }
      );
      return;
    }

    this.setState(
      (state) => {
        let totalAmount = 0;
        let requestListCount = 0;
        productPackages.forEach((productPack) => {
          const setAmount = Number(productPack.amount);
          const productKey = productPack.id + "_" + productPack.packageOption;

          if (state.pqpCard.items[productKey] === undefined) {
            state.pqpCard.items[productKey] = { ...productPack, amount: 0 };
          }
          switch (action) {
            case "add":
              state.pqpCard.requestWasSent = false;
              state.pqpCard.items[productKey].amount += setAmount;
              break;
            case "change":
              state.pqpCard.items[productKey].amount = setAmount;
              break;
            default:
          }
        });

        Object.keys(state.pqpCard.items).forEach(function (key) {
          if (
            state.pqpCard.items[key] === undefined ||
            state.pqpCard.items[key].amount < 1
          ) {
            delete state.pqpCard.items[key];
          } else {
            totalAmount += state.pqpCard.items[key].amount;
            requestListCount++;
          }
        });

        state.pqpCard.totalAmount = totalAmount;
        state.pqpCard.requestListCount = requestListCount;
        this.handleLocalStorage("set", "pqpCard", state.pqpCard);

        let setCardMenuIndex = Math.ceil(requestListCount / 4) - 1;
        setCardMenuIndex = setCardMenuIndex < 0 ? 0 : setCardMenuIndex;
        if (setCardMenuIndex < state.cardMenuIndex) {
          state.cardMenuIndex = setCardMenuIndex;
        }

        return state;
      },
      () => {
        if (action === "add") {
          // this.handleShowPqpCardMenu(true);
          this.showMessageBox(
            this.props.t("SUCCESSFUL ADDED TO REQUEST LIST"),
            "success-mark"
          );
        }
        if (this.state.pqpCard.totalAmount === 0) {
          this.handleShowPqpCardMenu(false);
        }
      }
    );
  }

  getValidUrlForLang(lang) {
    const params = this.getValidLangAndSite();
    const setPath = this.getValidPath(lang, params.site, params.extra);
    return process.env.PUBLIC_URL + setPath;
  }

  changeLanguage(lang) {
    const languageMapping = { de: "de_DE", en: "en_GB" };
    lang = languageMapping[lang] ? languageMapping[lang] : lang;

    i18next.changeLanguage(lang, (err, t) => {
      setDefaultLocale(lang);
      const params = this.getValidLangAndSite();
      const setPath = this.getValidPath(
        lang.substring(0, 2).toLowerCase(),
        params.site,
        params.extra
      );
      this.props.history.push(setPath);
    });
  }

  getValidLangAndSite() {
    const availableLanguages = ["de", "en"];
    const currentLang = this.props.i18n.language.substring(0, 2).toLowerCase();
    const matching = match("/:lang/:site/:extra?", {
      decode: decodeURIComponent,
    });
    const matchPath = matching(this.props.location.pathname);

    const lang =
      matchPath &&
      matchPath.params.lang !== undefined &&
      availableLanguages.includes(matchPath.params.lang)
        ? matchPath.params.lang
        : currentLang;
    const site =
      matchPath && matchPath.params.site !== undefined
        ? matchPath.params.site
        : "home";
    const extra =
      matchPath && matchPath.params.extra !== undefined
        ? matchPath.params.extra
        : "";

    return { lang, site, currentLang, extra };
  }

  getValidPath(lang, site, extra) {
    const toPathRepeated = compile("/:segment+", {
      encode: encodeURIComponent,
    });
    const segmentArray = [lang, site];

    if (extra) {
      segmentArray.push(extra);
    }

    const setPath = toPathRepeated({ segment: segmentArray });
    return setPath;
  }

  componentDidMount() {
    const params = this.getValidLangAndSite();
    if (params.lang !== params.currentLang) {
      this.changeLanguage(params.lang);
    }
  }

  render() {
    const siteParams = this.getValidLangAndSite();
    const currentLang = siteParams.currentLang;
    const setPath = this.getValidPath(
      siteParams.lang,
      siteParams.site,
      siteParams.extra
    );

    if (this.props.location.pathname !== setPath) {
      return <Redirect to={setPath} />;
    }

    const services = getServices(this.props.t);

    return (
      <Layout
        handleCardMenuIndex={this.handleCardMenuIndex}
        handleShowPqpCardMenu={this.handleShowPqpCardMenu}
        handlePqpCardUpdate={this.handlePqpCardUpdate}
        handleCurrentService={this.handleCurrentService}
        pqpCard={this.state.pqpCard}
        cardMenuDirection={this.state.cardMenuDirection}
        showPqpCardMenu={this.state.showPqpCardMenu}
        cardMenuIndex={this.state.cardMenuIndex}
        changeLanguage={this.changeLanguage}
        messageBoxState={this.state.messageBox}
        closeMessageBox={this.closeMessageBox}
        cookies={this.state.cookies}
        siteParams={siteParams}
      >
        <Suspense fallback={<LoadingSpinner />}>
          {this.state.cookies.stats && GA.init(this.state.cookies.stats) && (
            <GA.RouteTracker />
          )}
          <CookieBanner
            CustomCheckbox={(
              setId,
              setOnChange,
              setDefaultChecked,
              setText,
              isDisabled
            ) => (
              <CustomCheckboxRadio
                customCssClass="d-flex flex-row align-items-center"
                customIconCssClass="default-font-size"
                type="checkbox"
                id={setId}
                name={setId}
                onChange={setOnChange}
                defaultChecked={setDefaultChecked}
                isDisabled={isDisabled}
                labelElement={<div className="simple-label">{setText}</div>}
              />
            )}
            checkboxHeaderLabel={
              this.props.t("I CONSENT TO THE FOLLOWING COOKIES") + " :"
            }
            message={
              <div>
                {this.props.t(
                  "WE WANT TO USE SOME COOKIES TO HELP MAKING THE BEST POSSIBLE EXPERIENCE FOR YOU. THE NECESSARY COOKIES SIMPLY SAVE YOUR CONSENT SETTING, NOTHING ELSE. THE STATISTIC COOKIES HELP US GETTING SOME INSIGHT INTO YOUR USAGE OF OUR QUOTATION PORTAL, THE USABILITY COOKIES SAVE YOUR CONTACT DATA SO THAT YOU DONT HAVE TO RETYPE THEM EVERY TIME. FOR MORE DETAILS SEE OUR PRIVACY POLICY"
                )}
              </div>
            }
            declineButtonText={this.props.t("NO COOKIES PLEASE")}
            acceptButtonText={this.props.t("I CONSENT")}
            statisticsOptionText={this.props.t("STATISTICAL")}
            preferencesOptionText={this.props.t("USABILITY")}
            necessaryOptionText={this.props.t("NECESSARY")}
            showDeclineButton
            privacyPolicyLinkText={this.props.t("DATA PRIVACY")}
            policyLink={"/" + currentLang + Routes.privacy}
            onAccept={() => {
              if (this.firstCall.req) {
                this.firstCall.req = false;

                this.setState((state) => {
                  state.cookies.req = true;
                });
              }
            }}
            onAcceptPreferences={() => {
              if (this.firstCall.pref) {
                this.firstCall.pref = false;
                this.setState((state) => {
                  state.cookies.pref = true;
                });
              }
            }}
            onAcceptStatistics={() => {
              if (this.firstCall.stats) {
                this.firstCall.stats = false;

                this.setState((state) => {
                  state.cookies.stats = true;
                });
              }
            }}
            showMarketingOption={false}
          />
          <Switch>
            <Route path={"/:lang" + Routes.home}>
              <Helmet htmlAttributes={{ lang: currentLang }}>
                <title>{this.props.t("PQP_SITE_TITLE_STARTPAGE")}</title>
                <link
                  rel="alternate"
                  href={this.getValidUrlForLang("de")}
                  hrefLang="de"
                />
                <link
                  rel="alternate"
                  href={this.getValidUrlForLang("en")}
                  hrefLang="en"
                />
                <meta name="robots" content="index,follow" />
                <meta
                  name="description"
                  content={this.props.t("PQP_SITE_META_DESCRIPTION_STARTPAGE")}
                />
              </Helmet>
              <Home />
            </Route>
            <Route path={"/:lang" + Routes.products}>
              <Helmet htmlAttributes={{ lang: currentLang }}>
                <title>{this.props.t("PQP_SITE_TITLE_PRODUCT")}</title>
                <link
                  rel="alternate"
                  href={this.getValidUrlForLang("de")}
                  hrefLang="de"
                />
                <link
                  rel="alternate"
                  href={this.getValidUrlForLang("en")}
                  hrefLang="en"
                />
                <meta name="robots" content="index,follow" />
                <meta
                  name="description"
                  content={this.props.t("PQP_SITE_META_DESCRIPTION_PRODUCT")}
                />
              </Helmet>
              <Products
                handlePqpCardUpdate={this.handlePqpCardUpdate}
                handleShowPqpCardMenu={this.handleShowPqpCardMenu}
                cookies={this.state.cookies}
              />
            </Route>
            <Route path={"/:lang" + Routes.services}>
              <Helmet htmlAttributes={{ lang: currentLang }}>
                <title>{this.props.t("PQP_SITE_TITLE_SERVICE")}</title>
                <link
                  rel="alternate"
                  href={this.getValidUrlForLang("de")}
                  hrefLang="de"
                />
                <link
                  rel="alternate"
                  href={this.getValidUrlForLang("en")}
                  hrefLang="en"
                />
                <meta name="robots" content="index,follow" />
                <meta
                  name="description"
                  content={this.props.t("PQP_SITE_META_DESCRIPTION_SERVICE")}
                />
              </Helmet>
              <Services
                services={services}
                handleCurrentService={this.handleCurrentService}
              />
            </Route>

            <Route path={"/:lang" + Routes.requestWizard}>
              <Helmet htmlAttributes={{ lang: currentLang }}>
                <title>{this.props.t("PQP_SITE_TITLE_PRODUCT")}</title>
                <link
                  rel="alternate"
                  href={this.getValidUrlForLang("de")}
                  hrefLang="de"
                />
                <link
                  rel="alternate"
                  href={this.getValidUrlForLang("en")}
                  hrefLang="en"
                />
                <meta name="robots" content="noindex,nofollow" />
                <meta
                  name="description"
                  content={this.props.t("PQP_SITE_META_DESCRIPTION_PRODUCT")}
                />
              </Helmet>
              <RequestWizard
                lang={currentLang}
                handlePqpCardUpdate={this.handlePqpCardUpdate}
                handleContactInformation={this.handleContactInformation}
                pqpContactInformation={this.state.pqpContactInformation}
                pqpCard={this.state.pqpCard}
              />{" "}
            </Route>
            <Route path={"/:lang" + Routes.serviceWizard}>
              <Helmet htmlAttributes={{ lang: currentLang }}>
                <title>{this.props.t("PQP_SITE_TITLE_SERVICE")}</title>
                <link
                  rel="alternate"
                  href={this.getValidUrlForLang("de")}
                  hrefLang="de"
                />
                <link
                  rel="alternate"
                  href={this.getValidUrlForLang("en")}
                  hrefLang="en"
                />
                <meta name="robots" content="noindex,nofollow" />
                <meta
                  name="description"
                  content={this.props.t("PQP_SITE_META_DESCRIPTION_SERVICE")}
                />
              </Helmet>
              <ServiceWizard
                lang={currentLang}
                handleCurrentService={this.handleCurrentService}
                handleContactInformation={this.handleContactInformation}
                pqpContactInformation={this.state.pqpContactInformation}
                pqpCurrentService={this.state.pqpCurrentService}
                services={services}
              />
            </Route>
            <Route path={"/:lang" + Routes.newsletter}>
              <Helmet htmlAttributes={{ lang: currentLang }}>
                <title>
                  {this.props.t("PQP_SITE_TITLE_REGISTER_NEWSLETTER")}
                </title>
                <link
                  rel="alternate"
                  href={this.getValidUrlForLang("de")}
                  hrefLang="de"
                />
                <link
                  rel="alternate"
                  href={this.getValidUrlForLang("en")}
                  hrefLang="en"
                />
                <meta name="robots" content="noindex,nofollow" />
                <meta
                  name="description"
                  content={this.props.t("PQP_SITE_META_DESCRIPTION_NEWSLETTER")}
                />
              </Helmet>
              <RegisterNewsletter />
            </Route>
            <Route path={"/:lang" + Routes.contactinfo}>
              <Helmet htmlAttributes={{ lang: currentLang }}>
                <title>{this.props.t("PQP_SITE_TITLE_CONTACT_INFO")}</title>
                <link
                  rel="alternate"
                  href={this.getValidUrlForLang("de")}
                  hrefLang="de"
                />
                <link
                  rel="alternate"
                  href={this.getValidUrlForLang("en")}
                  hrefLang="en"
                />
                <meta name="robots" content="noindex,nofollow" />
                <meta
                  name="description"
                  content={this.props.t(
                    "PQP_SITE_META_DESCRIPTION_CONTACT_INFO"
                  )}
                />
              </Helmet>
              <ContactInfo />
            </Route>
            <Route path={"/:lang" + Routes.privacy}>
              <Helmet htmlAttributes={{ lang: currentLang }}>
                <title>{this.props.t("PQP_SITE_TITLE_PRIVACY_POLICY")}</title>
                <link
                  rel="alternate"
                  href={this.getValidUrlForLang("de")}
                  hrefLang="de"
                />
                <link
                  rel="alternate"
                  href={this.getValidUrlForLang("en")}
                  hrefLang="en"
                />
                <meta name="robots" content="noindex,nofollow" />
                <meta
                  name="description"
                  content={this.props.t(
                    "PQP_SITE_META_DESCRIPTION_PRIVACY_POLICY"
                  )}
                />
              </Helmet>
              <PrivacyPolicy />
            </Route>
            <Route path={"/:lang" + Routes.termsofservice}>
              <Helmet htmlAttributes={{ lang: currentLang }}>
                <title>{this.props.t("SITE_TITLE_TERMS_OF_SERVICE")}</title>
                <link
                  rel="alternate"
                  href={this.getValidUrlForLang("de")}
                  hrefLang="de"
                />
                <link
                  rel="alternate"
                  href={this.getValidUrlForLang("en")}
                  hrefLang="en"
                />
                <meta name="robots" content="noindex,nofollow" />
                <meta
                  name="description"
                  content={this.props.t(
                    "PQP_SITE_META_DESCRIPTION_TERMS_OF_SERVICE"
                  )}
                />
              </Helmet>
              <TermsOfService />
            </Route>
            <Route path={"/:lang" + Routes.tosversion}>
              <Helmet htmlAttributes={{ lang: currentLang }}>
                <title>{this.props.t("SITE_TITLE_TERMS_OF_SERVICE")}</title>
                <link
                  rel="alternate"
                  href={this.getValidUrlForLang("de")}
                  hrefLang="de"
                />
                <link
                  rel="alternate"
                  href={this.getValidUrlForLang("en")}
                  hrefLang="en"
                />
                <meta name="robots" content="noindex,nofollow" />
                <meta
                  name="description"
                  content={this.props.t(
                    "PQP_SITE_META_DESCRIPTION_TERMS_OF_SERVICE"
                  )}
                />
              </Helmet>
              <TosVersion coId={siteParams.extra} />
            </Route>
            <Route path={"/:lang" + Routes.tosforpyrexxservices}>
              <Helmet htmlAttributes={{ lang: currentLang }}>
                <title>
                  {this.props.t("SITE_TITLE_TOS_FOR_PYREXX_SERVICES")}
                </title>
                <link
                  rel="alternate"
                  href={this.getValidUrlForLang("de")}
                  hrefLang="de"
                />
                <link
                  rel="alternate"
                  href={this.getValidUrlForLang("en")}
                  hrefLang="en"
                />
                <meta name="robots" content="noindex,nofollow" />
                <meta
                  name="description"
                  content={this.props.t(
                    "PQP_SITE_META_DESCRIPTION_TOS_FOR_PYREXX_SERVICES"
                  )}
                />
              </Helmet>
              <TosForPyrexxServices />
            </Route>
            <Route path={"/:lang" + Routes.tosforpyrexxproductsales}>
              <Helmet htmlAttributes={{ lang: currentLang }}>
                <title>
                  {this.props.t("SITE_TITLE_TOS_FOR_PYREXX_PRODUCT_SALES")}
                </title>
                <link
                  rel="alternate"
                  href={this.getValidUrlForLang("de")}
                  hrefLang="de"
                />
                <link
                  rel="alternate"
                  href={this.getValidUrlForLang("en")}
                  hrefLang="en"
                />
                <meta name="robots" content="noindex,nofollow" />
                <meta
                  name="description"
                  content={this.props.t(
                    "PQP_SITE_META_DESCRIPTION_TOS_FOR_PYREXX_PRODUCT_SALES"
                  )}
                />
              </Helmet>
              <TosForPyrexxProductSales />
            </Route>
            <Route path={"/:lang" + Routes.imprint}>
              <Helmet htmlAttributes={{ lang: currentLang }}>
                <title>{this.props.t("SITE_TITLE_IMPRINT")} </title>
                <link
                  rel="alternate"
                  href={this.getValidUrlForLang("de")}
                  hrefLang="de"
                />
                <link
                  rel="alternate"
                  href={this.getValidUrlForLang("en")}
                  hrefLang="en"
                />
                <meta name="robots" content="index,follow" />
                <meta
                  name="description"
                  content={this.props.t("PQP_SITE_META_DESCRIPTION_IMPRINT")}
                />
              </Helmet>
              <Imprint />
            </Route>
            <Route path={"/:lang" + Routes.newsletterevent + "/:id"}>
              <Helmet htmlAttributes={{ lang: currentLang }}>
                <title>
                  {this.props.t("SITE_TITLE_NEWSLETTER_EVENT_SUBSCRIPTION")}
                </title>
                <link
                  rel="alternate"
                  href={this.getValidUrlForLang("de")}
                  hrefLang="de"
                />
                <link
                  rel="alternate"
                  href={this.getValidUrlForLang("en")}
                  hrefLang="en"
                />
                <meta name="robots" content="noindex,nofollow" />
                <meta
                  name="description"
                  content={this.props.t(
                    "PQP_SITE_META_DESCRIPTION_NEWSLETTER_EVENT_SUBSCRIPTION"
                  )}
                />
              </Helmet>
              <RegisterNewsletterEvent />
            </Route>
            <Route path={"/:lang" + Routes.confirmSub + "/:code"}>
              <Helmet htmlAttributes={{ lang: currentLang }}>
                <title>
                  {this.props.t("SITE_TITLE_NEWSLETTER_CONFIRM_SUBSCRIPTION")}
                </title>
                <link
                  rel="alternate"
                  href={this.getValidUrlForLang("de")}
                  hrefLang="de"
                />
                <link
                  rel="alternate"
                  href={this.getValidUrlForLang("en")}
                  hrefLang="en"
                />
                <meta name="robots" content="noindex,nofollow" />
                <meta
                  name="description"
                  content={this.props.t(
                    "PQP_SITE_META_DESCRIPTION_NEWSLETTER_CONFIRM_SUBSCRIPTION"
                  )}
                />
              </Helmet>
              <ConfirmSubscription />
            </Route>
            <Route path="*">
              <Helmet htmlAttributes={{ lang: currentLang }}>
                <title>{this.props.t("SITE_TITLE_404")} </title>
                <link
                  rel="alternate"
                  href={this.getValidUrlForLang("de")}
                  hrefLang="de"
                />
                <link
                  rel="alternate"
                  href={this.getValidUrlForLang("en")}
                  hrefLang="en"
                />
                <meta name="robots" content="noindex,nofollow" />
              </Helmet>
              <NotFound />
            </Route>
          </Switch>
        </Suspense>
      </Layout>
    );
  }
}

export default withTranslation("translation")(withRouter(App));

import React, { useCallback, useState } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

const SimpleLink = (props) => {
  const [popupVisible, setPopupVisible] = useState(false);

  const togglePopup = useCallback(() => {
    setPopupVisible(!popupVisible);
  }, [popupVisible]);

  const { renderModalPopup } = props;

  return (
    <>
      <a
        target={props.target}
        className={
          (!props.disableDefaultCssClass ? "simple-link " : "") +
          props.className
        }
        href={props.url}
        onClick={() => {
          if (props.onClick !== undefined) {
            props.onClick();
          } else if (props.renderModalPopup !== undefined) {
            togglePopup();
          }
        }}
      >
        {props.label}
      </a>
      {renderModalPopup !== undefined &&
        popupVisible &&
        renderModalPopup(popupVisible, togglePopup)}
    </>
  );
};

export default withTranslation("translation")(withRouter(SimpleLink));

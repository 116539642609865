import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import { Route } from "react-router-dom";

const GoogleAnalytics = (props) => {
  const logPageChange = useCallback(
    (pathname, search = "") => {
      const page = pathname + search;
      const { location } = window;
      ReactGA.set({
        page,
        location: `${location.origin}${page}`,
        anonymizeIp: true,
        ...props.options,
      });
      ReactGA.pageview(page);
    },
    [props.options]
  );

  useEffect(() => {
    logPageChange(props.location.pathname, props.location.search);
  }, [props.location.pathname, props.location.search, logPageChange]);

  return null;
};

GoogleAnalytics.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  options: PropTypes.object,
};

const RouteTracker = () => <Route component={GoogleAnalytics} />;

const init = () => {
  const isGAEnabled = process.env.NODE_ENV === "production";
  if (isGAEnabled) {
    ReactGA.initialize("UA-106971845-9");
  }

  return isGAEnabled;
};

const GoogleModalView = (modalName) => {
  if (process.env.NODE_ENV === "production") {
    ReactGA.modalview(modalName);
  }
};

export default {
  GoogleAnalytics,
  RouteTracker,
  init,
  GoogleModalView,
};

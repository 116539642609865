import React from "react";
import { withTranslation } from "react-i18next";
import SimpleLink from "./SimpleLink";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const RequestListInfo = (props) => {
  return (
    <Row className="justify-content-end">
      <Col xs="auto" md="auto" lg="auto">
        <SimpleLink
          className={
            "request-list-info " + (!props.currentCard ? " disabled" : "")
          }
          label={
            <>
              <Container className="pb-4">
                <Row className="align-items-center">
                  <Col className="p-0">{props.t(props.label)}</Col>
                  <Col xs="auto" md="auto" lg="auto" className="p-0">
                    <span
                      className={
                        "cardInfoCircle" + (props.currentCard ? " active" : "")
                      }
                    >
                      {props.currentCard}
                    </span>
                  </Col>
                </Row>
              </Container>
            </>
          }
          onClick={props.handleShowPqpCardMenu}
        />
      </Col>
    </Row>
  );
};
export default withTranslation("translation")(RequestListInfo);

import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../Environment";

const mutation = graphql`
  mutation ConfirmNewsletterSubscriptionMutation($token: String!) {
    confirmNewsletterSubscription(token: $token) {
      type
      message
    }
  }
`;

export default (token, callback) => {
  const variables = {
    token: token,
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (requestData) => {
      callback(requestData);
    },
    onError: (err) => console.error(err),
  });
};

import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

const SimpleList = (props) => {
  return (
    <Container className="simple-list">
      <Row as="nav">
        {props.label && (
          <Col as="h3" className="simple-list-header" lg={12}>
            {props.label}
          </Col>
        )}
        <ul className="list-unstyled">
          {props.values.map((value, i) => (
            <Col
              key={props.label + i}
              as="li"
              className="simple-list-value py-1"
              lg={12}
            >
              {value}
            </Col>
          ))}
        </ul>
      </Row>
    </Container>
  );
};

export default SimpleList;

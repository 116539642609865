import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./styles/Main.scss";
import App from "./components/App";
import "font-awesome/css/font-awesome.min.css";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { I18nextProvider } from "react-i18next";

import i18 from "./i18";

const Loader = () => <div>loading...</div>;

ReactDOM.render(
  <I18nextProvider i18n={i18}>
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <App />
      </Suspense>
    </BrowserRouter>
  </I18nextProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

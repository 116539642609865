import React, { useCallback } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

const ParagraphInfo = (props) => {
  const generateParagraphsContent = useCallback(() => {
    const filteredContent = Object.keys(props.content).filter(
      (item) => item !== null
    );

    return (
      filteredContent?.map((key, indexKey) => {
        const structuredDocument = props.content[key];

        const paragraphsArray =
          structuredDocument?.paragraphs?.filter((item) => item !== null) || [];

        const title = structuredDocument?.title || "NO TITLE DEFINED";
        const coId = structuredDocument?.co_id || "NO CO_ID DEFINED";
        const coDate = structuredDocument?.date || "NO CO DATE DEFINED";
        return (
          <Card
            as="article"
            key={"paragraph-wrapper" + indexKey}
            className="paragraph-wrapper"
          >
            <Card.Header as="h1" className="paragraph-title">
              {title}
            </Card.Header>
            {paragraphsArray.map((paragraph, paragraphIndex) => {
              return (
                <Card.Body key={"paragraphIndex" + paragraphIndex}>
                  <div className="paragraph-block">
                    <div className="paragraph-block-number">
                      {paragraph?.number}
                    </div>
                    <div className="paragraph-block-name">
                      {paragraph?.name}
                    </div>
                  </div>
                  {paragraph?.sections
                    ?.filter((item) => item !== null)
                    ?.map((section, sectionIndex) => {
                      return (
                        <div
                          key={"sectionIndex" + sectionIndex}
                          className="d-flex paragraph-section"
                        >
                          <div className="paragraph-section-number">
                            {section?.number}.
                          </div>
                          <div
                            className="paragraph-section-text"
                            dangerouslySetInnerHTML={{
                              __html: section?.text,
                            }}
                          />
                        </div>
                      );
                    })}
                </Card.Body>
              );
            })}
            <div className="paragraph-footer">
              <Row className="justify-content-end">
                <Col xs="auto" md="auto" lg="auto">
                  co_{coId}
                </Col>
              </Row>
              <Row className="justify-content-end">
                <Col xs="auto" md="auto" lg="auto">
                  {coDate}
                </Col>
              </Row>
            </div>
          </Card>
        );
      }) || "NO CONTENT FOUND"
    );
  }, [props]);

  return (
    <Container className="paragraph-info">
      <Row>
        <Col xs={12} md={12}>
          {generateParagraphsContent()}
        </Col>
      </Row>
    </Container>
  );
};

export default ParagraphInfo;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ReturnType = "ERROR" | "OK" | "%future added value";
export type RegisterNewsletterEmailAndPersonMutationVariables = {|
  email: string,
  name: string,
  tagId: number,
|};
export type RegisterNewsletterEmailAndPersonMutationResponse = {|
  +registerNewsletterEmailAndPerson: ?{|
    +type: ?ReturnType,
    +message: ?string,
  |}
|};
export type RegisterNewsletterEmailAndPersonMutation = {|
  variables: RegisterNewsletterEmailAndPersonMutationVariables,
  response: RegisterNewsletterEmailAndPersonMutationResponse,
|};
*/


/*
mutation RegisterNewsletterEmailAndPersonMutation(
  $email: String!
  $name: String!
  $tagId: Int!
) {
  registerNewsletterEmailAndPerson(email: $email, name: $name, tag_id: $tagId) {
    type
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tagId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "tag_id",
        "variableName": "tagId"
      }
    ],
    "concreteType": "Response",
    "kind": "LinkedField",
    "name": "registerNewsletterEmailAndPerson",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RegisterNewsletterEmailAndPersonMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RegisterNewsletterEmailAndPersonMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "24491c43553f835ba12c108424bbf3a4",
    "id": null,
    "metadata": {},
    "name": "RegisterNewsletterEmailAndPersonMutation",
    "operationKind": "mutation",
    "text": "mutation RegisterNewsletterEmailAndPersonMutation(\n  $email: String!\n  $name: String!\n  $tagId: Int!\n) {\n  registerNewsletterEmailAndPerson(email: $email, name: $name, tag_id: $tagId) {\n    type\n    message\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9640019ede3ac1dc4c751b393d6b573e';

module.exports = node;

import React from "react";
import { withTranslation } from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RouteLink from "./RouteLink";
import { Routes } from "../configs/RouteConfig";

const PageSwitcher = (props) => {
  return (
    <Row>
      <Col xs={12} lg={6} className="text-right">
        <RouteLink
          addClassName="navigation-menu"
          label={props.t("SERVICES")}
          addActiveClassName="navigation-menu-active"
          link={Routes.services}
        />
      </Col>
      <Col xs={12} lg={6} className="text-left">
        <RouteLink
          addClassName="navigation-menu"
          label={props.t("PRODUCTS")}
          addActiveClassName="navigation-menu-active"
          link={Routes.products}
        />
      </Col>
    </Row>
  );
};

export default withTranslation("translation")(PageSwitcher);

import React, { useCallback } from "react";
import { withTranslation } from "react-i18next";
import "floating-label-react/styles.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import "react-datepicker/dist/react-datepicker.css";
import { StyledInput as StyledInputLib } from "pyrexx-react-library";
import Feedback from "react-bootstrap/Feedback";

const StyledInput = (props) => {
  const generateInput = useCallback(() => {
    const setFieldValue = props.setFieldValue;
    switch (props.type) {
      case "text":
        return (
          <StyledInputLib
            className="styled-inputLib"
            type="input"
            name={props.name}
            id={props.id || props.name}
            onChange={props.onChange}
            value={props.value}
            label={props.placeholder}
            feedbackMessage={props.touched && props.error ? props.error : ""}
            feedbackStyle="invalid"
            setFieldValue={setFieldValue}
          />
        );
      case "textarea":
        return (
          <StyledInputLib
            className="styled-input"
            type="textarea"
            name={props.name}
            id={props.id}
            onChange={props.onChange}
            value={props.value}
            label={props.placeholder}
            feedbackMessage={props.touched && props.error ? props.error : ""}
            feedbackStyle="invalid"
            setFieldValue={setFieldValue}
          />
        );
      case "select":
        // eslint-disable-next-line no-case-declarations
        let selectObjects = [];
        selectObjects = props.optionsHtmlElemets?.map((item) => {
          return { value: item.props.value, label: item.props.children };
        });
        return (
          <StyledInputLib
            className="styled-input"
            type="reactSelect"
            name={props.name}
            id={props.id}
            label={props.placeholder}
            setFieldValue={setFieldValue}
            onChange={props.onChange}
            value={props.value}
            feedbackMessage={props.touched && props.error ? props.error : ""}
            reactSelectOptions={selectObjects}
          />
        );
      case "date":
        return (
          <StyledInputLib
            className="styled-input"
            locale="de"
            type="dateUi"
            name={props.name}
            id={props.id}
            onChange={props.onChange}
            value={props.value}
            label={props.placeholder}
            feedbackMessage={props.touched && props.error ? props.error : ""}
            setFieldValue={setFieldValue}
          />
        );
      default:
        return "";
    }
  }, [
    props.setFieldValue,
    props.type,
    props.name,
    props.id,
    props.onChange,
    props.value,
    props.placeholder,
    props.touched,
    props.error,
    props.optionsHtmlElemets,
  ]);

  const generatePopupIcon = useCallback(() => {
    const translate = props.t;

    if (props.popupInfoText !== undefined) {
      return (
        <OverlayTrigger
          trigger="click"
          key="top"
          placement="top"
          rootClose
          overlay={
            <Popover id="popover-positioned-top">
              <Popover.Content>
                {translate(props.popupInfoText)}
              </Popover.Content>
            </Popover>
          }
        >
          <i className="fa fa-info-circle info-button" aria-hidden="true" />
        </OverlayTrigger>
      );
    }
  }, [props.popupInfoText, props.t]);

  const isInvalid = props.touched && props.error && props.error;
  const feedbackType = "d-block " + (isInvalid ? "invalid" : "neutral");

  return (
    <div className="styled-input">
      {generatePopupIcon()}
      {generateInput()}
      {!props.hideFeedback && (
        <Feedback type={feedbackType}>{props.staticFeedback}</Feedback>
      )}
    </div>
  );
};

export default withTranslation("translation")(StyledInput);

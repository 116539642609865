import React, { useCallback } from "react";
import Form from "react-bootstrap/Form";

const CustomCheckboxRadio = (props) => {
  const generateIcon = useCallback(
    (inputType) => {
      const disabledClass =
        props.isDisabled &&
        props.isDisabled.disabled !== undefined &&
        "disabled";

      if (inputType === "checkbox") {
        return props.defaultChecked ? (
          <span
            data-icon="&#xe907;"
            className={"icon " + disabledClass + " " + props.customIconCssClass}
            aria-hidden="true"
          />
        ) : (
          <span
            data-icon="&#xe914;"
            className={"icon " + disabledClass + " " + props.customIconCssClass}
            aria-hidden="true"
          />
        );
      } else {
        return props.defaultChecked ? (
          <span
            data-icon="&#xe909;"
            className={"icon " + disabledClass + " " + props.customIconCssClass}
            aria-hidden="true"
          />
        ) : (
          <span
            data-icon="&#xe90a;"
            className={"icon " + disabledClass + " " + props.customIconCssClass}
            aria-hidden="true"
          />
        );
      }
    },
    [props.defaultChecked, props.customIconCssClass, props.isDisabled]
  );

  return (
    <label
      className={
        "custom-checkbox-radio " +
        props.customCssClass +
        " " +
        (props.defaultChecked && " " + props.activeCssClass)
      }
      htmlFor={props.id}
    >
      <span className="invisible">
        <Form.Check
          type={props.type}
          name={props.name}
          id={props.id}
          value={props.value}
          onChange={props.onChange}
          defaultChecked={props.defaultChecked}
          label={false}
          onBlur={props.onBlur}
          {...props.isDisabled}
        />
      </span>
      {props.hideIcon !== true && generateIcon(props.type)}
      {props.labelElement}
    </label>
  );
};

export default CustomCheckboxRadio;

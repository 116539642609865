import React from "react";
import { withTranslation } from "react-i18next";
import Image from "react-bootstrap/Image";

const LangSwitcher = (props) => {
  return (
    <span className="lang-switcher">
      {props.langs.map((value) => {
        const isActiveLang =
          props.i18n.language === value.locale ? "active" : "inactive";
        return (
          <button
            key={"langSwitch" + value.country}
            className={"flag-link " + isActiveLang}
            onClick={() => {
              props.changeLanguage(value.locale);
            }}
          >
            <Image
              style={{ width: "20px", height: "20px" }}
              src={"/img/" + value.country + "-active.svg"}
            />
          </button>
        );
      })}
    </span>
  );
};
export default withTranslation("translation")(LangSwitcher);

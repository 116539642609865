import React from "react";
import { withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const RouteLink = (props) => {
  const currentLanguage = props.i18n.language.substring(0, 2).toLowerCase();

  const addActiveClass = props.addActiveClassName
    ? props.addActiveClassName
    : "";
  const addClassName = props.addClassName ? props.addClassName : "";

  return (
    <NavLink
      exact
      onClick={
        props.onClick !== undefined
          ? props.onClick
          : () => {
              return false;
            }
      }
      to={"/" + currentLanguage + props.link}
      activeClassName={addActiveClass}
      className={addClassName}
    >
      {props.label}
    </NavLink>
  );
};
export default withTranslation("translation")(RouteLink);

import React from "react";
import { withTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import SimpleLink from "./SimpleLink";
import LangSwitcher from "./LangSwitcher";
import { Links } from "../configs/LinkConfig";
import MediaQuery from "react-responsive";
import MediaVariables from "../styles/base/sharedVariables.scss";
import { checkIfKioskRoute } from "../utils/Helper";

const GlobalHeader = (props) => {
  return (
    <Container>
      <Row as="nav" className="align-items-center">
        <Col xs={6} md={6} lg={6}>
          {!checkIfKioskRoute(props.site) && (
            <SimpleLink
              label={
                <>
                  <MediaQuery minDeviceWidth={MediaVariables.bigPage}>
                    {props.t("BACK TO PYREXX HOMEPAGE")}
                  </MediaQuery>
                  <MediaQuery maxDeviceWidth={MediaVariables.smallPage}>
                    <i
                      className="fa fa-home margin-horizontal-right-10"
                      aria-hidden="true"
                    />
                    {props.t("PYREXX")}
                  </MediaQuery>
                </>
              }
              url={Links.backToHomepage}
            />
          )}
        </Col>
        <Col xs={6} md={6} lg={6} className="text-right">
          <LangSwitcher
            changeLanguage={props.changeLanguage}
            langs={[
              { locale: "de_DE", country: "de" },
              { locale: "en_GB", country: "en" },
            ]}
          />
        </Col>
      </Row>
    </Container>
  );
};
export default withTranslation("translation")(GlobalHeader);

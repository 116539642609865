/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Language = "DE" | "EN" | "%future added value";
export type TosForPyrexxServicesQueryVariables = {|
  lang: Language
|};
export type TosForPyrexxServicesQueryResponse = {|
  +DRINKWATERANALYSIS: ?{|
    +co_id: ?number,
    +date: ?string,
    +title: ?string,
    +paragraphs: ?$ReadOnlyArray<?{|
      +number: ?number,
      +name: ?string,
      +sections: ?$ReadOnlyArray<?{|
        +number: ?number,
        +name: ?string,
        +text: ?string,
      |}>,
    |}>,
  |},
  +ESTATE: ?{|
    +co_id: ?number,
    +date: ?string,
    +title: ?string,
    +paragraphs: ?$ReadOnlyArray<?{|
      +number: ?number,
      +name: ?string,
      +sections: ?$ReadOnlyArray<?{|
        +number: ?number,
        +name: ?string,
        +text: ?string,
      |}>,
    |}>,
  |},
|};
export type TosForPyrexxServicesQuery = {|
  variables: TosForPyrexxServicesQueryVariables,
  response: TosForPyrexxServicesQueryResponse,
|};
*/


/*
query TosForPyrexxServicesQuery(
  $lang: Language!
) {
  DRINKWATERANALYSIS: tosDrinkWaterAnalysis(lang: $lang) {
    co_id
    date
    title
    paragraphs {
      number
      name
      sections {
        number
        name
        text
      }
    }
  }
  ESTATE: tosEstate(lang: $lang) {
    co_id
    date
    title
    paragraphs {
      number
      name
      sections {
        number
        name
        text
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lang"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "lang",
    "variableName": "lang"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "co_id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "date",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "title",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Paragraph",
    "kind": "LinkedField",
    "name": "paragraphs",
    "plural": true,
    "selections": [
      (v2/*: any*/),
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Section",
        "kind": "LinkedField",
        "name": "sections",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "text",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v5 = [
  {
    "alias": "DRINKWATERANALYSIS",
    "args": (v1/*: any*/),
    "concreteType": "StructuredDocument",
    "kind": "LinkedField",
    "name": "tosDrinkWaterAnalysis",
    "plural": false,
    "selections": (v4/*: any*/),
    "storageKey": null
  },
  {
    "alias": "ESTATE",
    "args": (v1/*: any*/),
    "concreteType": "StructuredDocument",
    "kind": "LinkedField",
    "name": "tosEstate",
    "plural": false,
    "selections": (v4/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TosForPyrexxServicesQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TosForPyrexxServicesQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "b957c99dddded8e398472eb5dd3b2648",
    "id": null,
    "metadata": {},
    "name": "TosForPyrexxServicesQuery",
    "operationKind": "query",
    "text": "query TosForPyrexxServicesQuery(\n  $lang: Language!\n) {\n  DRINKWATERANALYSIS: tosDrinkWaterAnalysis(lang: $lang) {\n    co_id\n    date\n    title\n    paragraphs {\n      number\n      name\n      sections {\n        number\n        name\n        text\n      }\n    }\n  }\n  ESTATE: tosEstate(lang: $lang) {\n    co_id\n    date\n    title\n    paragraphs {\n      number\n      name\n      sections {\n        number\n        name\n        text\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd4071882a5f33cf71f5aacf802c91d6c';

module.exports = node;

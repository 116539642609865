import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import CardListItem from "./CardListItem";
import Carousel from "react-bootstrap/Carousel";
import { Routes } from "../configs/RouteConfig";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import GA from "../utils/GoogleAnalytics";

const CardMenu = (props) => {
  useEffect(() => {
    if (props.showPqpCardMenu && props.cookies.stats) {
      GA.GoogleModalView("CardMenu");
    }
  }, [props.showPqpCardMenu, props.cookies.stats]);

  let countItems = 0;
  const cardItemsCarousel = Object.keys(props.pqpCard.items)
    .map(function (key, value) {
      const item = props.pqpCard.items[key];
      countItems++;
      return (
        <CardListItem
          key={item.id + "_" + item.packageOption}
          id={item.id}
          countNumber={countItems}
          label={item.groupName + (item.name ? ": " + item.name : "")}
          imageUrl={item.image}
          amount={item.amount}
          minOrderAmount={item.minOrderAmount}
          handlePqpCardUpdate={props.handlePqpCardUpdate}
          packageOption={item.packageOption}
          handleShowPqpCardMenu={props.handleShowPqpCardMenu}
        />
      );
    })
    .reduce((prev, element, index) => {
      if (index % 4 === 0) {
        prev.push([]);
      }
      prev[prev.length - 1].push(element);

      return prev;
    }, [])
    .map((cardlistitem, index) => {
      const keyId = cardlistitem.reduce(
        (accu, currentValue) => accu + "_" + currentValue.key,
        ""
      );
      return (
        <Carousel.Item
          className="card-menu-content-carousel-item"
          key={"cardListItemCarusel_" + keyId}
        >
          {cardlistitem}
        </Carousel.Item>
      );
    });

  return (
    <Modal
      show={props.showPqpCardMenu}
      onHide={() => {
        props.handleShowPqpCardMenu(false);
      }}
      dialogClassName=""
    >
      <Container
        className={
          "card-menu overflow-auto " + (!props.showPqpCardMenu ? "hide" : "")
        }
      >
        <Row className="pt-3">
          <Col xs="auto" md="auto" lg="auto" className="heading heading--tiny">
            {props.t("REQUEST LIST")}
          </Col>
          <Col className="text-right">
            <button
              className="close"
              onClick={() => {
                props.handleShowPqpCardMenu(false);
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Col>
        </Row>
        <Row>
          <hr width="100%" align="center" />
          <Col className="text-right">
            <span className="list-style-1-information-display-a--title">
              {props.t("TOTAL AMOUNT") + " "}
            </span>
            <span className="list-style-1-information-display-a--content">
              {props.totalAmount}
            </span>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <Carousel
              interval={null}
              activeIndex={props.cardMenuIndex}
              direction={props.cardMenuDirection}
              onSelect={props.handleCardMenuIndex}
              controls={cardItemsCarousel.length > 1}
              indicators={cardItemsCarousel.length > 1}
              wrap={false}
              fade={false}
              slide={false}
              prevLabel=""
              nextLabel=""
              prevIcon={
                <span
                  data-icon="&#xe901;"
                  className="simple-link"
                  aria-hidden="true"
                />
              }
              nextIcon={
                <span
                  data-icon="&#xe902;"
                  className="simple-link"
                  aria-hidden="true"
                />
              }
            >
              {cardItemsCarousel}
            </Carousel>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs="auto" md="auto" lg="auto">
            <button
              className="button--primary mb-2"
              onClick={() => {
                props.history.push("/" + props.lang + Routes.requestWizard);
                props.handleShowPqpCardMenu(false);
              }}
              disabled={!props.pqpCard.totalAmount}
            >
              {props.t("START REQUEST NOW")}
            </button>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};
export default withTranslation("translation")(withRouter(CardMenu));

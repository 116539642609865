import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ConfirmNewsletterSubscriptionMutation from "../../mutations/ConfirmNewsletterSubscription";
import Alert from "react-bootstrap/Alert";
import CardContainer from "../../components/CardContainer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { portalTheme } from "pyrexx-react-library";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";

const ConfirmSubscription = (props) => {
  const { t } = useTranslation("translation");
  const [status, setStatus] = useState({
    requestLoading: false,
    sendSuccess: false,
  });
  const { code } = useParams();
  useEffect(() => {
    if (!status.sendSuccess && code) {
      setStatus({ requestLoading: true, sendSuccess: false });
      ConfirmNewsletterSubscriptionMutation(code, (requestData) => {
        setStatus({ requestLoading: false, sendSuccess: true });
      });
    }
  }, [code, status.sendSuccess]);

  const generateBodyContent = () => {
    return (
      <Container className="p-0">
        <Row className="m-0 justify-content-center">
          <Col className="p-0" xs={12} md={10} lg={5}>
            <Row className="m-0 py-2 justify-content-center">
              <Col className="p-0" xs={12}>
                {status.sendSuccess && (
                  <Alert variant="success" className="align-items-center">
                    {t("SUBSCRIPTION CONFIRMED")}
                  </Alert>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        {status.requestLoading && (
          <LoadingSpinner
            theme={{
              components: {
                spinner: {
                  /**
                   * Has to be a { css } oject from styled-components
                   */
                  fontSize: portalTheme.font.size.bodySmall,
                  fontWeight: portalTheme.font.weight.regular,
                  size: "20px",
                },
              },
            }}
            style={{
              position: "unset",
              backgroundColor: "white",
            }}
          />
        )}
      </Container>
    );
  };

  return (
    <CardContainer
      metaTitle={t("PQP_SITE_TITLE_CONFIRM_SUBSCRIPTION")}
      siteTitle={t("CONFIRM SUBSCRIPTION")}
      bodyContent={generateBodyContent()}
    />
  );
};

export default ConfirmSubscription;

import React, { useCallback, useState } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import pqpLogo from "../assets/pyrexx_logo.svg";
import PageSwitcher from "./PageSwitcher";
import RequestListInfo from "./RequestListInfo";
import MediaQuery from "react-responsive";
import MediaVariables from "../styles/base/sharedVariables.scss";
import MobileMenu from "./MobileMenu";
import RouteLink from "./RouteLink";
import { Routes } from "../configs/RouteConfig";
import { match } from "path-to-regexp";
import { checkIfKioskRoute } from "../utils/Helper";

const PqpHeader = (props) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = useCallback(
    (setDirect) => {
      if (setDirect !== undefined) {
        setMobileMenuOpen(setDirect);
      } else {
        setMobileMenuOpen(!mobileMenuOpen);
      }
    },
    [mobileMenuOpen]
  );

  const getNeededComponentforHeader = useCallback(
    (place) => {
      const matching = match("/:lang/:site/:extra?", {
        decode: decodeURIComponent,
      });
      const matchPath = matching(props.location.pathname);

      switch ("/" + matchPath.params.site) {
        case Routes.requestWizard:
          switch (place) {
            case "right":
              return (
                <RouteLink
                  addClassName="simple-link"
                  link={Routes.products}
                  label={
                    <div className="pb-4">{props.t("BACK TO OVERVIEW")}</div>
                  }
                />
              );
            case "middle":
              return (
                <span className="pqp-header-title">
                  {props.t("PRODUCT REQUEST")}
                </span>
              );
            default:
              return "";
          }
        case Routes.services:
          switch (place) {
            case "":
              return "";
            case "middle":
              return <PageSwitcher className="page-switcher" />;
            default:
              return "";
          }
        case Routes.serviceWizard:
          switch (place) {
            case "right":
              return (
                <RouteLink
                  addClassName="simple-link"
                  link={Routes.services}
                  label={
                    <div className="pb-4">{props.t("BACK TO OVERVIEW")}</div>
                  }
                />
              );
            case "middle":
              return (
                <span className="pqp-header-title">
                  {props.t("SERVICE REQUEST")}
                </span>
              );
            default:
              return "";
          }
        case Routes.products:
          switch (place) {
            case "right":
              return (
                <RequestListInfo
                  label="REQUEST LIST"
                  currentCard={props.currentCard}
                  handleShowPqpCardMenu={() => {
                    if (props.totalAmount !== 0) {
                      props.handleShowPqpCardMenu(true);
                    }
                  }}
                />
              );
            case "middle":
              return <PageSwitcher className="page-switcher" />;
            default:
              return "";
          }
        default:
          switch (place) {
            case "right":
              return "";
            case "middle":
              return <PageSwitcher className="page-switcher" />;
            default:
              return "";
          }
      }
    },
    [props]
  );

  return (
    <>
      <Container style={{ height: "100%" }}>
        <Row style={{ height: "100%" }}>
          <Col
            xs={6}
            md={6}
            lg={4}
            className="text-left"
            style={{ display: "flex", alignItems: "center" }}
          >
            {!checkIfKioskRoute(props.site) ? (
              <RouteLink
                addClassName=""
                link={Routes.home}
                label={<Image src={pqpLogo} className="pqp-header-logo" />}
              />
            ) : (
              <Image src={pqpLogo} className="pqp-header-logo" />
            )}
          </Col>
          <MediaQuery minDeviceWidth={MediaVariables.bigPage}>
            <Col md={4} lg={4} className="align-self-end text-center">
              {!checkIfKioskRoute(props.site) &&
                getNeededComponentforHeader("middle")}
            </Col>
            <Col md={4} lg={4} className="align-self-end text-right">
              {!checkIfKioskRoute(props.site) &&
                getNeededComponentforHeader("right")}
            </Col>
          </MediaQuery>
          <MediaQuery maxDeviceWidth={MediaVariables.smallPage}>
            <Col xs={6} md={6} lg={4} className="align-self-center text-right">
              {!checkIfKioskRoute(props.site) && (
                <i
                  onClick={() => {
                    toggleMobileMenu();
                  }}
                  className="fa fa-bars mobile-menu-button"
                  aria-hidden="true"
                />
              )}
            </Col>
          </MediaQuery>
        </Row>
      </Container>
      <MediaQuery maxDeviceWidth={MediaVariables.smallPage}>
        {!checkIfKioskRoute(props.site) && (
          <MobileMenu
            eventTypes="click"
            open={mobileMenuOpen}
            toggleMobileMenu={toggleMobileMenu}
            currentCard={props.currentCard}
            handleShowPqpCardMenu={() => {
              if (props.totalAmount !== 0) {
                props.handleShowPqpCardMenu(true);
              }
            }}
          />
        )}
      </MediaQuery>
    </>
  );
};
export default withTranslation("translation")(withRouter(PqpHeader));

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Language = "DE" | "EN" | "%future added value";
export type TosForPyrexxProductSalesQueryVariables = {|
  lang: Language
|};
export type TosForPyrexxProductSalesQueryResponse = {|
  +TOSFORPYREXXPRODUCTSALES: ?{|
    +co_id: ?number,
    +date: ?string,
    +title: ?string,
    +paragraphs: ?$ReadOnlyArray<?{|
      +number: ?number,
      +name: ?string,
      +sections: ?$ReadOnlyArray<?{|
        +number: ?number,
        +name: ?string,
        +text: ?string,
      |}>,
    |}>,
  |}
|};
export type TosForPyrexxProductSalesQuery = {|
  variables: TosForPyrexxProductSalesQueryVariables,
  response: TosForPyrexxProductSalesQueryResponse,
|};
*/


/*
query TosForPyrexxProductSalesQuery(
  $lang: Language!
) {
  TOSFORPYREXXPRODUCTSALES: tosSales(lang: $lang) {
    co_id
    date
    title
    paragraphs {
      number
      name
      sections {
        number
        name
        text
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lang"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": "TOSFORPYREXXPRODUCTSALES",
    "args": [
      {
        "kind": "Variable",
        "name": "lang",
        "variableName": "lang"
      }
    ],
    "concreteType": "StructuredDocument",
    "kind": "LinkedField",
    "name": "tosSales",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "co_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Paragraph",
        "kind": "LinkedField",
        "name": "paragraphs",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Section",
            "kind": "LinkedField",
            "name": "sections",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TosForPyrexxProductSalesQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TosForPyrexxProductSalesQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "cc2fcad9d7d3acf17484a98306cf350f",
    "id": null,
    "metadata": {},
    "name": "TosForPyrexxProductSalesQuery",
    "operationKind": "query",
    "text": "query TosForPyrexxProductSalesQuery(\n  $lang: Language!\n) {\n  TOSFORPYREXXPRODUCTSALES: tosSales(lang: $lang) {\n    co_id\n    date\n    title\n    paragraphs {\n      number\n      name\n      sections {\n        number\n        name\n        text\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2a65744c210b0eee32848a2c19295e67';

module.exports = node;

import React from "react";
import { withTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const CopyrightFooter = (props) => {
  return (
    <Container className="py-3">
      <Row className="align-self-center justify-content-between">
        <Col
          as="p"
          xs={12}
          md="auto"
          className="copyright-text align-self-center"
        >
          {props.t("PYREXX - SIMPLE, CLEAN, SAFE")}
        </Col>
      </Row>
    </Container>
  );
};

export default withTranslation("translation")(CopyrightFooter);

import React from "react";
import Modal from "react-bootstrap/Modal";
import { withTranslation } from "react-i18next";

const LoadingSpinner = (props) => {
  return (
    <Modal size="sm" show>
      <div className="spinner-wrapper">
        <div className="spinner" />
        <div className="text-center">{props.t("PLEASE WAIT")}</div>
      </div>
    </Modal>
  );
};

export default withTranslation("translation")(LoadingSpinner);

import React from "react";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

const CardContainer = (props) => {
  return (
    <>
      <Helmet>
        <title>{props.metaTitle}</title>
      </Helmet>
      <Container className={props.className}>
        <Row className="justify-content-center">
          <Col xs={12} sm={12}>
            <Card>
              <Card.Header className="site-title">
                {props.siteTitle}
              </Card.Header>
              <Card.Body>{props.bodyContent}</Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default withTranslation("translation")(CardContainer);

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import RegisterNewsletterEmailAndPersonMutation from "../../mutations/RegisterNewsletterEmailAndPersonMutation";
import Alert from "react-bootstrap/Alert";
import CardContainer from "../../components/CardContainer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { StyledInput, ButtonPrimary, portalTheme } from "pyrexx-react-library";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";

const RegisterNewsletterEvent = (props) => {
  const { t } = useTranslation("translation");
  const [status, setStatus] = useState({
    requestLoading: false,
    sendSuccess: false,
  });
  const [lastEmail, setLastEmail] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (lastEmail) {
        setStatus({
          requestLoading: false,
          sendSuccess: false,
        });
        setLastEmail(false);
      }
    }, 10000);
    return () => clearTimeout(timeOutId);
  }, [lastEmail]);

  const schema = Yup.object({
    email: Yup.string()
      .email(t("INSERT VALID EMAIL"))
      .required(t("INSERT VALID EMAIL")),
    name: Yup.string().required(t("PLEASE INSERT A NAME")),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
    },
    validationSchema: schema,
    onSubmit: (values, formikb) => {
      formikb.resetForm();
      setStatus({ requestLoading: true, sendSuccess: false });
      RegisterNewsletterEmailAndPersonMutation(
        values.email,
        values.name,
        parseInt(id),
        (requestData) => {
          setLastEmail(values.email);
          setStatus({ requestLoading: false, sendSuccess: true });
        }
      );
    },
  });

  const generateBodyContent = () => {
    return (
      <Container className="p-0">
        <Row className="m-0 justify-content-center">
          <Col className="p-0" xs={12} md={10} lg={5}>
            <Row className="my-4">
              <Col className="p-0">
                <StyledInput
                  type="input"
                  name="name"
                  id="name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  label={t("FULL NAME")}
                  feedbackMessage={formik.touched.name && formik.errors.name}
                  feedbackStyle="invalid"
                  setFieldValue={formik.setFieldValue}
                  disabled={status.requestLoading}
                />
              </Col>
            </Row>
            <Row className="my-4">
              <Col className="p-0">
                <StyledInput
                  type="input"
                  name="email"
                  id="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  label={t("EMAIL")}
                  feedbackMessage={formik.touched.email && formik.errors.email}
                  feedbackStyle="invalid"
                  setFieldValue={formik.setFieldValue}
                  disabled={status.requestLoading}
                />
              </Col>
            </Row>
            <Row className="my-5 justify-content-center">
              <Col className="p-0" xs="content">
                <ButtonPrimary
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                  disabled={status.requestLoading}
                  style={{
                    margin: "0px",
                    height: "3rem",
                    paddingLeft: "2rem",
                    paddingRight: "2rem",
                  }}
                >
                  {t("REGISTER NOW")}
                </ButtonPrimary>
              </Col>
            </Row>
            <Row className="m-0 py-2 justify-content-center">
              <Col className="p-0" xs={12}>
                {status.sendSuccess && (
                  <Alert variant="success" className="align-items-center">
                    {t("SUCCESSFULLY SUBSCRIBED TO THE NEWSLETTER") +
                      ": " +
                      lastEmail}
                  </Alert>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        {status.requestLoading && (
          <LoadingSpinner
            theme={{
              components: {
                spinner: {
                  /**
                   * Has to be a { css } oject from styled-components
                   */
                  fontSize: portalTheme.font.size.bodySmall,
                  fontWeight: portalTheme.font.weight.regular,
                  size: "20px",
                },
              },
            }}
            style={{
              position: "unset",
              backgroundColor: "white",
            }}
          />
        )}
      </Container>
    );
  };

  return (
    <CardContainer
      metaTitle={t("PQP_SITE_TITLE_REGISTER_EVENT_NEWSLETTER")}
      siteTitle={t("NEWSLETTER REGISTRATION")}
      bodyContent={generateBodyContent()}
    />
  );
};

export default RegisterNewsletterEvent;

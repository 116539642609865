import React, { useEffect } from "react";
import GlobalHeader from "./GlobalHeader";
import CardMenu from "./CardMenu";
import PqpHeader from "./PqpHeader";
import { withTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LinksFooter from "./LinksFooter";
import SocialFooter from "./SocialFooter";
import CopyRightFooter from "./CopyrightFooter";
import { MessageBox } from "pyrexx-react-library";

const Layout = (props) => {
  const isSticky = (e) => {
    const header = document.querySelector(".sticky-header");
    const pqpHeaderLogo = document.querySelector(".pqp-header-logo");
    const scrollTop = window.scrollY;
    if (scrollTop >= 40) {
      header.classList.add("is-sticky");
      pqpHeaderLogo.classList.add("is-sticky-logo");
    } else {
      header.classList.remove("is-sticky");
      pqpHeaderLogo.classList.remove("is-sticky-logo");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  return (
    <Container fluid>
      <Row as="header" className="global-header align-items-end">
        <Col xs={12} md={12} lg={12}>
          <GlobalHeader
            changeLanguage={props.changeLanguage}
            site={props.siteParams.site}
          />
        </Col>
      </Row>
      <Row as="nav" className="sticky-header">
        <Col xs={12} md={12} lg={12} className="pqp-header">
          <PqpHeader
            currentCard={props.pqpCard.requestListCount}
            handleShowPqpCardMenu={props.handleShowPqpCardMenu}
            totalAmount={props.pqpCard.totalAmount}
            site={props.siteParams.site}
          />
        </Col>
      </Row>
      <Row as="main" id="content-box" className="content-box">
        <Col xs={12} md={12} lg={12} className="pt-4 pb-4">
          {React.cloneElement(props.children, { ...props })}
        </Col>
        <MessageBox
          isVisible={props.messageBoxState.visible}
          closeMessageBox={props.closeMessageBox}
          statusColor={props.messageBoxState.statusColorClass}
          updated={props.messageBoxState.updated}
        >
          {props.messageBoxState.message}
        </MessageBox>
        {props.pqpCard.totalAmount !== 0 && (
          <CardMenu
            cookies={props.cookies}
            cardMenuDirection={props.cardMenuDirection}
            cardMenuIndex={props.cardMenuIndex}
            handleCardMenuIndex={props.handleCardMenuIndex}
            showPqpCardMenu={props.showPqpCardMenu}
            handleShowPqpCardMenu={props.handleShowPqpCardMenu}
            handlePqpCardUpdate={props.handlePqpCardUpdate}
            pqpCard={props.pqpCard}
            totalAmount={props.pqpCard.totalAmount}
            lang={props.i18n.language.substring(0, 2).toLowerCase()}
          />
        )}
      </Row>
      <Row as="footer">
        <Col>
          <Row className="links-footer">
            <Col>
              <LinksFooter site={props.siteParams.site} />
            </Col>
          </Row>
          <Row className="social-footer">
            <Col>
              <SocialFooter site={props.siteParams.site} />
            </Col>
          </Row>
          <Row className="copyright-footer">
            <Col>
              <CopyRightFooter />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default withTranslation("translation")(Layout);
